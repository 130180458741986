.dashboard {
  .top-bar {
    width: 100%;
    height: 24px;
    background-image: url("../../images/dashboard_bar.png");
    background-size: 100% 100%;
  }

  .layout {
    display: flex;
    flex-direction: row;

    .left {
      min-width: 300px;
    }

    .right {
      width: 100%;
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .dashboard {
    .top-bar {
      width: 100%;
      height: 24px;
      background-image: url("../../images/dashboard_bar.png");
      background-size: 100% 100%;
    }

    .layout {
      display: flex;
      flex-direction: column;

      .left {
        min-width: 300px;
      }

      .right {
        width: 100%;
      }
    }
  }
}
