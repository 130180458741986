.admin-practitioner-dashboard-home {
  padding: 50px 30px;

  .pending-approval {
    color: #ec7363;
  }

  .title-bar {
    display: flex;
    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
    }
    .datetime-pill {
      position: absolute;
      right: 30px;
      transform: translateY(-10px);
    }
  }

  .yellow-box {
    background: rgba(253, 188, 91, 0.2);
    border-radius: 16px;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 30px;

    

    .session-card {
      width: calc(50% - 15px);
      min-height: 100px;
      background-color: white;
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);
      border-radius: 48px 0px 0px 0px;
      padding: 30px 20px;
      display: block;

      .row-container {
        display: flex;
      }

      .half {
        width: 50%;
      }

      .photo {
        width: 70px;
        height: 70px;
        border-radius: 1000px;
        border: 3px $LumiBlue solid;
        display: block;
      }

      h3 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: $LumiRose;
        margin-top: 15px;
      }

      h4 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $LumiBodyText;
        white-space: nowrap;

        span {
          font-style: italic;
        }
      }

      .date {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: $LumiBodyText;
        margin-bottom: 5px;
        display: block;
      }

      .day-time {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        text-align: right;
        color: $LumiBodyText;
        display: block;
      }

      .calendar-icon {
        width: 16px;
        transform: translateY(-3px);
        margin-right: 10px;
      }

      .specialties {
        font-family: "Raleway";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        color: $LumiBodyText;
        white-space: nowrap;

      }

      .join-call {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $LumiGray;
        background-color: white;
        border: 2px $LumiBlue solid;
        border-radius: 10px 0px 10px 0px;
        padding: 12px 30px;

        &:hover {
          background-color: $LumiBlue;
          color: white;
        }
      }

      .circle-icon {
        display: inline-block;
        background-color: $LumiBodyText;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        img {
          width: 16px;
          height: 16px;
        }
      }

      .text-right {
        text-align: right;
      }

      .flex-end {
        justify-content: flex-end;
      }

      a {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: $LumiGray;
      }
      
    }
  }
}

.ul-list {
  list-style: none !important;

  li {
    // display: inline-block !important;
    margin: 10px !important; /* add spacing between items */

  a {
    color: #69c1be !important;
  }

  }

}




