.book-practitioner {
  width: 80%;
  margin: auto;

  h3 {
    color: $LumiBodyText;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  .schedule-message {
    color: $LumiBodyText;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    margin-bottom: 30px;
  }

  .input-wrapper {
    text-align: left;
    display: flex;

    .icon-globe {
      width: 20px;
      transform: translateY(3px);
      margin-right: 10px;
    }

    .timezone-select__control {
      width: auto;
      min-width: 150px;
      color: $LumiBodyText;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      outline: none;
    }

    .timezone-select__control--is-focused {
      border: none;
      outline: none;
      box-shadow: none;
      outline-color: transparent;
    }

    .timezone-select__indicator-separator {
      opacity: 0;
    }
  }

  .calendar-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .calendar-arrow {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 1px solid var(--graphite-06, #d0d9de);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: lightgray;
      }

      img {
        width: 16px;
      }
    }
    .calendar-monthyear {
      color: #000;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 180%; /* 28.8px */
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .react-calendar {
    width: 95%;
    margin: auto;
    margin-top: 50px;
    border: none;

    .react-calendar__navigation {
      .react-calendar__navigation__label {
        color: #000;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 180%; /* 28.8px */

        pointer-events: none;
        cursor: default;
      }

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        opacity: 0;
        pointer-events: none;
        cursor: default;
      }

      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button {
        font-size: 22px;
        color: black;
        padding: 0px;
        border-radius: 32px;
        border: 1px solid var(--graphite-06, #d0d9de);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        line-height: auto;
        background-color: transparent;

        &:hover {
          background-color: lightgray;
        }
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__days {
          abbr {
            display: none;
          }

          .react-calendar__tile {
            &:hover {
              background-color: transparent;
            }
          }

          .react-calendar__month-view__days__day[disabled] {
            background-color: transparent;

            //Set to important so current date do not get displayed as selected.
            .custom-calendar-tile {
              color: $LumiBodyText !important;
              background-color: transparent !important;
            }
          }

          .react-calendar__month-view__days__day--neighboringMonth {
            opacity: 0;
          }

          .react-calendar__tile--now {
            background-color: transparent;

            .custom-calendar-tile {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              color: $LumiBodyText;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;

              background-color: #e8e8e8;
            }
          }

          .react-calendar__tile--active {
            background-color: transparent;

            .custom-calendar-tile {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              color: $LumiBodyText;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;

              color: $LumiYellow;
              background-color: $LumiBodyText;
            }
          }

          .custom-calendar-tile {
            width: 100%;
            height: 50px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            color: $LumiBodyText;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .is-available-this-day {
            border-radius: 8px;
            background: var(--lumi-pink, #f8ab9b);
          }
        }

        .react-calendar__month-view__weekdays__weekday {
          abbr {
            color: $LumiBodyText;
            font-family: Raleway;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
          }
        }
      }
    }
  }

  .session-duration {
    margin-top: 50px;
    color: #000;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .duration-select__control {
    margin: auto;
    width: 200px;
    min-width: 150px;
    color: $LumiBodyText;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    outline: none;

    border-radius: 64px;
    border: 1px solid $LumiYellow;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }

  .duration-select__control--is-focused {
    border: 1px solid $LumiYellow;
    outline: none;
    box-shadow: none;
    outline-color: transparent;

    &:hover {
      border: 1px solid $LumiYellow;
    }
  }

  .duration-select__indicator-separator {
    opacity: 0;
  }

  .selected-date {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 28.8px */
    margin-top: 50px;
  }

  .session-hour {
    width: 200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    border-radius: 16px 0px;
    border: 1px solid $LumiPink;
    background-color: white;

    color: #3d3030;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }

  .session-hour-active {
    width: 200px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    border-radius: 16px 0px;
    border: 1px solid $LumiBodyText;
    background-color: $LumiBodyText;

    color: $LumiYellow;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}
