.header {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-left: $SidePadding;
  padding-right: $SidePadding;
  padding-top: 10px;

  #logo {
    width: 250px;
  }

  #logo_mobile {
    width: 135px;
    display: none;
  }

  nav {
    display: flex;
    margin-top: 10px;
    ul {
      display: inline-block;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        list-style: none;
        a {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 13.5px;
          color: $LumiBodyText;
          letter-spacing: 1px;
          text-decoration: none;
          display: inline-block;
          padding: 12px 25px;
          border-radius: 30px 0px 30px 0px;
          cursor: pointer;

          &:hover {
            color: $LumiBlue;
          }
        }

        .get-matched {
          background-color: $LumiRose;
          color: white;

          &:hover {
            color: white;
          }
        }
      }
    }
  }

  #hamburger {
    transform: scale(0.8);
    margin-top: 9px;
    margin-left: 15px;
    display: none;
    #menuToggle {
      display: block;
      position: relative;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
    }

    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;

      opacity: 0;
      z-index: 2;

      -webkit-touch-callout: none;
    }

    #menuToggle span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;

      background: $LumiOrange;
      border-radius: 3px;

      z-index: 1;

      transform-origin: 4px 0px;

      transition:
        transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: $LumiOrange;
    }

    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }

  @media only screen and (max-width: $MobileViewThreshold) {
    #header {
      display: block;
    }

    #hamburger {
      display: block;
      top: 20px;
      right: 30px;
    }

    #logo {
      display: none;
    }
    #logo_mobile {
      display: inline;
    }

    nav {
      height: 1px;
      opacity: 0;
      ul {
        display: none;
        opacity: 0;
      }
    }

    .nav-open {
      transition: all 0.5s ease;
      position: absolute;
      width: 100%;
      height: auto;
      padding: 30px;
      padding-top: 0px;
      top: 120px;
      right: 0px;
      display: block;
      background-color: white;
      opacity: 1;
      z-index: 9;
      border-bottom: 1px solid #dedede;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 1;
        li {
          width: 100%;
          text-align: right;
          a {
            text-align: right;
          }
        }
      }
    }
  }
}
