.client-settings {
  padding: 50px 30px;
  .title-bar {
    display: flex;
    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
    }
    .datetime-pill {
      position: absolute;
      right: 30px;
      transform: translateY(-10px);
    }
  }

  .dash-client-settings-left {
    width: calc(75% - 20px);
    float: left;
    padding-top: 30px;

    .verification-box {
      background-color: #fef2f0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      .verification-widget {
        .verification-widget-inner {
          color: $LumiBodyText;
          font-size: 12px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 700;
          line-height: 180%;

          border-radius: 8px;
          border: 1px solid $LumiRose;
          padding: 13px;
          .verification-widget-text {
            color: #000;
            font-size: 16px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: block;

            .unverified {
              color: #ec7363;
            }

            .verified {
              color: #69a042;
            }
          }

          .verification-info-text {
            display: block;
            margin-top: 7px;
          }
        }
      }
      .verification-form {
        width: 100%;

        .verification-form-label {
          color: $LumiBodyText;
          font-size: 16px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
        }

        .verification-input {
          width: 100%;
          color: $LumiBodyText;
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 13px;
          margin-bottom: 20px;

          border-radius: 12px;
          border: 1px solid #b0bfc6;
          background: #fff;
          outline: none;
        }

        .verification-select__control--is-focused {
          border: none;
          outline: none;
          box-shadow: none;
          outline-color: transparent;
        }

        .verification-select__indicator-separator {
          opacity: 0;
        }

        .verification-select__control {
          width: 100%;
          color: $LumiBodyText;
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 6px;
          margin-bottom: 20px;

          border-radius: 12px;
          border: 1px solid #b0bfc6;
          background: #fff;
          outline: none;
        }

        .verification-photo {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 200px;

          color: $LumiBodyText;
          font-size: 16px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          .image-file-input {
            display: none;
          }

          .verification-photo-img {
            width: 100%;
          }
        }

        .verification-form-left {
          float: left;
          width: calc(50% - 10px);
        }
        .verification-form-right {
          float: right;
          width: calc(50% - 10px);
        }
      }
    }

    .personal-details-box {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      .input-wrapper-10 {
        width: calc(10% - 20px);
      }

      .input-wrapper-50 {
        width: calc(50% - 20px);
      }

      .input-wrapper-60 {
        width: calc(60% - 20px);
      }

      .input-wrapper-70 {
        width: calc(70% - 20px);
      }

      .input-wrapper-20 {
        width: calc(20% - 20px);
      }

      .input-wrapper-30 {
        width: calc(30% - 20px);
      }

      .input-wrapper-100 {
        width: calc(100% - 30px);
      }

      input[disabled] {
        background-color: #eeeeee !important;
        font-weight: bold;
      }

      .personal-details-form {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        .personal-details-form-label {
          color: $LumiBodyText;
          font-size: 16px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
        }

        .personal-details-form-input {
          width: 100%;
          color: $LumiBodyText;
          font-size: 16px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 13px;
          margin-bottom: 20px;

          border-radius: 12px;
          border: 1px solid #b0bfc6;
          background: #fff;
          outline: none;
        }

        .personal-details-form-input-checkbox {
          color: $LumiBodyText;
          font-size: 16px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
          margin-bottom: 20px;

          input {
            scale: 1.2;
            margin: 5px;
          }
        }

        .postal-select__control--is-focused {
          border: none;
          outline: none;
          box-shadow: none;
          outline-color: transparent;
        }

        .postal-select__indicator-separator {
          opacity: 0;
        }

        .postal-select__control {
          width: 100%;
          color: $LumiBodyText;
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 6px;
          margin-bottom: 20px;

          border-radius: 12px;
          border: 1px solid #b0bfc6;
          background: #fff;
          outline: none;
        }

        .dob-flex {
          display: flex;
          > div {
            width: calc(33% - 10px);
            margin-right: 10px;
            .dob-select__control--is-focused {
              border: none;
              outline: none;
              box-shadow: none;
              outline-color: transparent;
            }

            .dob-select__indicator-separator {
              opacity: 0;
            }

            .dob-select__control {
              width: 100%;
              color: $LumiBodyText;
              font-size: 18px;
              font-family: Raleway;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 6px;
              margin-bottom: 20px;

              border-radius: 12px;
              border: 1px solid #b0bfc6;
              background: #fff;
              outline: none;
            }
          }
        }
      }

      .password {
        width: 100%;
        color: $LumiBodyText;
        font-size: 16px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 13px;
        margin-bottom: 20px;

        border-radius: 12px;
        border: 1px solid #b0bfc6;
        background: #fff;
        outline: none;
      }

      .password-wrapper {
        width: 100%;
        position: relative;

        .eye {
          width: 34px;
          position: absolute;
          right: 15px;
          top: 10px;
          cursor: pointer;
        }
      }
    }

    .description-box {
      background-color: #fbe3e0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 30px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      textarea {
        width: 100%;
        border: none;
        background-color: transparent;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;

        resize: none;
        overflow: hidden;

        &:active,
        &:focus {
          outline: none;
          border: 1px solid grey;
          border-radius: 10px;
          background: white;
        }
      }

      .quill {
        width: 100%;

        .ql-toolbar {
          background-color: #f0f0f0;
        }

        .ql-container {
          background-color: white;
          .ql-editor {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }

    .fee-structure-box {
      background-color: rgba(253, 188, 91, 0.2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      .fee-structure-form {
        width: 100%;

        .fee-structure-form-label {
          color: $LumiBodyText;
          font-size: 16px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
        }

        .fee-structure-form-input {
          width: 100%;
          color: $LumiBodyText;
          font-size: 18px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 13px;
          margin-bottom: 20px;

          border-radius: 12px;
          border: 1px solid #b0bfc6;
          background: #fff;
          outline: none;
        }

        .fee-structure-form-left {
          float: left;
          width: 300px;
        }
        .fee-structure-form-right {
          float: left;
          width: 300px;
          margin-left: 20px;
        }
      }
    }

    .credit-card-box {
      background-color: rgba(105, 193, 190, 0.2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;

        img {
          margin-right: 10px;
          transform: translate(0, -3px);
        }
      }

      .credit-card-widget {
        .credit-card-widget-inner {
          color: $LumiBodyText;
          font-size: 12px;
          font-family: Raleway;
          font-style: normal;

          border-radius: 8px;
          border: 1px solid #69a042;
          padding: 13px;
          .credit-card-text {
            color: #000;
            font-size: 16px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: block;
          }

          .credit-card-info-text {
            display: block;
            margin-top: 7px;
          }
        }
      }

      .add-new-card {
        width: 300px;
        margin-top: 30px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        color: #ffffff;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;

        border-radius: 20px 0px;
        background-color: #ec7363;
      }
    }
    .client-settings-separator {
      width: 100%;
      height: 1px;
      background-color: lightgray;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .client-settings-actions {
      width: 100%;
      color: #000;
      font-size: 20px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 50px;

      .link {
        display: inline-block;
        color: $LumiBodyText;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .save-button {
      font-family: Raleway;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: $LumiBodyText;
      margin-top: 20px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 15px 10px 15px;
      border-radius: 20px 0px 20px 0px;
      cursor: pointer;
      background-color: $LumiRose;
      color: white;
      outline: none;
      border: none;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .dash-client-settings-right {
    width: 25%;
    min-width: 100px;
    float: right;
    margin-left: 20px;
  }
}

.client-settings-modal {
  .modal-content {
    .modal-body {
      color: $LumiOrange;
      font-size: 12px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      padding: 30px;
      h3 {
        color: #000;
        font-size: 20px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      h4 {
        color: $LumiBodyText;
        font-size: 16px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        margin-top: 30px;
      }

      .client-settings-modal-input {
        width: 100%;
        color: $LumiBodyText;
        font-size: 18px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 13px;

        border-radius: 12px;
        border: 1px solid #b0bfc6;
        background: #fff;
        outline: none;
      }
    }
    .modal-footer {
      justify-content: flex-start;
      padding: 25px;
      padding-top: 0px;
      .btn-lumirose {
        display: flex;
        height: 48px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        outline: none;

        border-radius: 20px 0px;
        background: $LumiRose;
        border-color: $LumiRose;
      }
      .btn-secondary {
        display: flex;
        height: 48px;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        outline: none;

        border-radius: 20px 0px;
      }
    }
  }
}
