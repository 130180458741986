.practitioner-filter {
  padding-left: 15px;
  padding-right: 15px;

  .filters {
    width: auto;
    display: inline-flex;
    border: 2px solid $LumiBlue;
    border-radius: 10px 0px 10px 0px;
    padding: 1px;
    padding-top: 1px;

    .labels:first-child {
      font-family: "Raleway";
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: 0.02em;
      color: $LumiGray;
      padding-top: 10px;
      margin-left: 0px;
    }

    .labels {
      padding-top: 10px;
      margin-left: 20px;
      margin-right: 10px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: 0.02em;
      color: $LumiBodyText;
    }

    .selects {
      .practitioner-select__control {
        border: none;
        margin-top: 5px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiBodyText;
        margin-top: 5px;
      }

      .practitioner-select__control--is-focused {
        border: none;
        outline: none;
        box-shadow: none;
        outline-color: transparent;
      }

      .practitioner-select__indicator-separator {
        display: none;
      }

      .practitioner-select__menu {
        min-width: 200px !important;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiBodyText;
        min-width: 80px;
      }
    }
  }

  .left {
    float: left;
  }

  .right {
    font-family: "Raleway";
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.02em;
    color: $LumiGray;
    margin-top: 25px;
    float: right;

    div {
      margin-top: 5px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .icons {
      width: 50px;
      height: 50px;
      background: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 60%;
      }
    }

    .active {
      background: $LumiBlue;
    }
  }
}

@media only screen and (max-width: $MobileViewThreshold) {
  .practitioner-filter {
    padding-left: 15px;
    padding-right: 15px;

    .filters {
      width: auto;
      display: inline-flex;
      flex-wrap: wrap;
      border: 2px solid $LumiBlue;
      border-radius: 10px 0px 10px 0px;
      padding: 1px;
      padding-top: 1px;

      .labels:first-child {
        width: 100%;
        font-family: "Raleway";
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiGray;
        padding-top: 10px;
        margin-left: 0px;
      }

      .labels {
        padding-top: 10px;
        margin-left: 0px;
        margin-right: 10px;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiBodyText;
        width: 100%;
      }

      .selects {
        width: 100%;
        .practitioner-select__control {
          border: none;
          margin-top: 5px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiBodyText;
          margin-top: 5px;
        }

        .practitioner-select__control--is-focused {
          border: none;
          outline: none;
          box-shadow: none;
          outline-color: transparent;
        }

        .practitioner-select__indicator-separator {
          display: none;
        }

        .practitioner-select__menu {
          min-width: 200px !important;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiBodyText;
          min-width: 80px;
        }
      }
    }

    .left {
      float: left;
    }

    .right {
      font-family: "Raleway";
      font-style: italic;
      font-weight: 700;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: 0.02em;
      color: $LumiGray;
      margin-top: 25px;
      float: right;

      div {
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .icons {
        width: 50px;
        height: 50px;
        background: white;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 60%;
        }
      }

      .active {
        background: $LumiBlue;
      }
    }
    .display-mode {
      display: none !important;
    }
  }
}
