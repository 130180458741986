/*Fonts Import*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&family=Maven+Pro&family=Raleway:ital,wght@0,300;0,500;0,600;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Summer Lovers";
  src: url("../fonts/SummerLover400.otf") format("opentype");
}

/*Variables*/
@import "variables/variables.scss";

/*Containers/Pages*/
@import "pages/homepage.scss";
@import "pages/clientlogin.scss";
@import "pages/clientsignup.scss";
@import "pages/practitionersignup.scss";
@import "pages/practitionerlogin.scss";
@import "pages/getstarted.scss";
@import "pages/practitioners.scss";
@import "pages/dashboard.scss";
@import "pages/clientdashboardhome.scss";
@import "pages/practitionerdashboardhome.scss";
@import "pages/practitionercalendar.scss";
@import "pages/practitionerprofile.scss";
@import "pages/practitionercerts.scss";
@import "pages/practitionerprofilepage.scss";
@import "pages/adminpractitionerprofilepage.scss";
@import "pages/adminpractitionerlist.scss";
@import "pages/practitionersettings.scss";
@import "pages/practitionermessages.scss";
@import "pages/practitionerclients.scss";
@import "pages/clientmessages.scss";
@import "pages/clientsessions.scss";
@import "pages/clientprofile.scss";
@import "pages/faq.scss";
@import "pages/practitionerschedule.scss";
@import "pages/coachsetting.scss";

/*Reusable Components*/
@import "components/header.scss";
@import "components/footer.scss";
@import "components/practionersignupbody.scss";
@import "components/quotebox.scss";
@import "components/practitionerfilter.scss";
@import "components/practitionerslist.scss";
@import "components/ratingwidget.scss";
@import "components/dashboardsidebar.scss";
@import "components/dashboardheader.scss";
@import "components/datetimepill.scss";
@import "components/practitionerschedule.scss";

/*Custom styles for 3rd-party packages*/
@import "third-party/toastify.scss";
@import "third-party/bootstrap.scss";

/*Globals*/
@import "global.scss";
