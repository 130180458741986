.quote-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-left: $SidePadding;
  margin-right: $SidePadding;
  background: #fdf4f3;
  border-radius: 100px 0px 0px 0px;
  font-family: "Summer Lovers";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 101px;
  color: $LumiRose;

  img {
    width: 100px;
    display: block;
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .quote-box {
    font-size: 52px;
    text-align: center;
    margin: 50px 10px 50px 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 60px;
  }
}
