.rating-widget {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 25px;
    margin: 4px;
  }
}
