#homepage {
  padding-left: $SidePadding;
  padding-right: $SidePadding;

  #banner {
    display: flex;
    align-items: center;
    background-color: grey;
    height: 590px;
    margin-top: 20px;
    border-radius: 180px 0px 0px 0px;

    background-image: conic-gradient(
        from -10deg at 50.2% 100%,
        #00000082 0deg,
        rgba(0, 0, 0, 0) 22.5deg,
        #00000082 359.96deg,
        #00000082 360deg
      ),
      linear-gradient(0deg, rgba(37, 50, 56, 0.07), rgba(37, 50, 56, 0.07)),
      url(../../images/homepage_banner.png);
    background-position: center;
    background-size: cover;

    #content {
      width: 50%;
      color: white;
      margin-left: 120px;

      h1 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 72px;
        line-height: 100%;
      }

      h3 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        margin-top: 20px;
        letter-spacing: 1px;
        line-height: 160%;
      }

      a {
        font-family: Raleway;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: $LumiBodyText;
        margin-top: 20px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 20px 25px 20px 25px;
        border-radius: 30px 0px 30px 0px;
        cursor: pointer;
        background-color: $LumiRose;
        color: white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }

  #specialties {
    margin-top: 30px;

    ul {
      display: flex;
      gap: 20px;
      width: 95%;
      margin: auto;
      padding: 0;

      li {
        display: block;
        width: 100%;

        img {
          display: block;
          width: 100%;
          background-color: grey;
          border-radius: 9900px;
          object-fit: cover;
        }

        .name {
          width: 100%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 15.5px;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          text-align: center;
          color: white;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 2px;
          padding-right: 2px;
          border-radius: 25px 0px 25px 0px;
          margin-top: -30px;
          position: relative;
          z-index: 1;
        }

        .rose {
          background-color: $LumiRose;
        }

        .orange {
          background-color: $LumiOrange;
        }

        .blue {
          background-color: $LumiBlue;
        }

        .yellow {
          background-color: $LumiYellow;
        }

        .pink {
          background-color: $LumiPink;
        }

        .lightgreen {
          background-color: $LumiLightGreen;
        }
      }
    }
  }

  #how-it-works {
    width: 100%;
    background-color: #e1f3f2;
    border-radius: 200px 0px 0px 0px;
    padding-top: 1px;
    margin-top: 60px;
    padding-top: 50px;
    padding-bottom: 60px;

    h1 {
      font-family: "Jost";
      font-weight: 500;
      font-style: normal;
      font-size: 46px;
      text-align: center;
      color: $LumiBlue;
      margin-bottom: 50px;
    }

    .how-items {
      display: flex;
      margin-top: 40px;

      .how-item {
        width: 25%;
        text-align: center;

        .icon {
          width: 220px;
          border-radius: 9000px;
          border: 4px solid;
        }

        .title {
          font-family: "Raleway";
          font-weight: 600;
          font-style: normal;
          font-size: 24px;
          text-align: center;
          margin-top: 30px;
        }

        .description {
          width: 80%;
          margin: auto;
          font-family: "Raleway";
          font-weight: 300;
          font-style: normal;
          text-align: center;
          margin-top: 20px;
          font-size: 16px;
        }

        .border-rose {
          border-color: $LumiRose;
        }

        .border-orange {
          border-color: $LumiOrange;
        }

        .border-blue {
          border-color: $LumiBlue;
        }

        .border-yellow {
          border-color: $LumiYellow;
        }

        .border-pink {
          border-color: $LumiPink;
        }

        .border-lightgreen {
          border-color: $LumiLightGreen;
        }
      }
    }
  }

  #get-matched {
    margin-top: 100px;
    text-align: center;

    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: $LumiRose;
    }

    h2 {
      font-family: "Summer Lovers";
      font-style: normal;
      font-weight: 400;
      font-size: 85px;
      line-height: 100%;
      text-align: center;
      color: $LumiYellow;
    }

    p {
      display: block;
      width: 70%;
      margin: auto;
      padding-top: 30px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.02em;
      color: $LumiBodyText;
    }

    a {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 28px;
      gap: 10px;
      background: $LumiRose;
      border-radius: 30px 0px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      margin: auto;
      margin-top: 50px;
    }
  }

  #practitioners-wrap {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 50px;
    background: #fef5f2;
    border-radius: 0px 176px 0px 0px;

    h1 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 54px;
      line-height: 120%;
      text-align: center;
      color: $LumiBlue;
    }

    p {
      display: block;
      width: 75%;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 160%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #455b66;
    }

    .asterisk {
      width: 50px;
    }

    h2 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: $LumiBlue;
      text-align: center;
    }

    .practitioner-card-container {
      background-image: url("../../images/bg_border.png");
      background-size: 100% 100%;
      border-radius: 33px 0px;
      padding: 2px;

      .practitioner-card {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: white;
        border-radius: 32px 0px;

        img {
          width: 100%;
          border-radius: 999px;
        }

        .top-badge {
          font-family: "Raleway";
          font-weight: normal;
          font-weight: 700;
          letter-spacing: 0.5px;
          font-size: 10px;
          background-color: $LumiRose;
          color: white;
          border-radius: 4px;
          padding: 1px 2px;
          text-align: center;
          margin: auto;
          margin-top: -12px;
          position: relative;
          z-index: 1;
        }

        .name {
          font-family: "Jost";
          font-weight: normal;
          font-weight: 500;
          font-size: 24px;
          color: $LumiRose;
        }

        .position {
          font-family: "Raleway";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
        }

        .message {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          margin-top: 20px;
        }

        .rate {
          width: auto;
          margin: auto;
          margin-top: 20px;
          text-align: center;

          .figure {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: black;
          }

          .persession {
            font-family: "Raleway";
            font-style: italic;
            font-weight: 300;
            font-size: 16px;
            color: $Graphite;
            margin-left: 20px;
          }
        }
      }
    }

    a {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 28px;
      gap: 10px;
      background: $LumiOrange;
      border-radius: 30px 0px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      margin: auto;
    }
  }

  #benefits-mobile {
    display: none;
  }

  #benefits {
    margin-top: 100px;
    background-image: url("../../images/bg_benefits.png");
    background-size: cover;
    border-radius: 0px 180px 0px 180px;

    #phones {
      #phones {
        width: 700px;
        min-width: 200px;
        margin-left: -20px;
        margin-top: -40px;
        position: relative;
        z-index: 2;
      }
    }

    #content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-box {
        width: 90%;
        margin-top: 70px;

        h1 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          color: white;
        }

        .list {
          display: block;
          margin: 0;
          padding: 0;
          margin-top: 40px;

          li {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 20px;

            .num {
              width: 50px;
              height: 50px;
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: 22px;
              text-align: center;
              color: white;
              border: 4px solid white;
              border-radius: 1000px;
              padding-top: 3px;
              margin-right: 20px;
              display: inline-block;
            }

            .text {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: 22px;
              line-height: 28px;
              color: #ffffff;
              text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              display: contents;
            }
          }
        }
      }
    }
  }

  #goodreads {
    width: 90%;
    margin: auto;
    margin-top: 100px;

    .good-reads-script-container {
      /*min-width: 300px;*/
      text-align: center;

      .good-reads-script {
        width: 100%;
        max-width: 320px;
        margin: auto;
      }
    }

    .strips {
      width: 100%;
      height: 8px;
      background-image: url("../../images/strip.png");
      background-size: cover;
      position: absolute;
      bottom: 0px;
    }

    #articles {
      display: flex;
      width: 80%;
      margin: auto;
      margin-top: 70px;
      text-align: center;

      .article {
        width: 33.3%;

        .article-photo {
          width: 200px;
          height: 200px;
          border-radius: 9000px;
        }

        h3 {
          font-family: "Jost";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: $LumiBlue;
        }

        .divider {
          width: 80px;
          height: 7px;
          background-color: $LumiYellow;
          margin: auto;
          margin-top: 30px;
          margin-bottom: 30px;
          border-radius: 10px;
        }

        .article-card {
          width: 90%;
          height: 450px;
          border: 4px solid $LumiYellow;
          border-radius: 0px 0px 0px 64px;
          padding-top: 130px;
          padding-bottom: 50px;
          padding-left: 10px;
          padding-right: 10px;
          margin-top: -100px;

          .article-snippet {
            display: block;
            padding-left: 30px;
            padding-right: 30px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
          }
        }
      }
    }
  }

  #emailform {
    margin: auto;
    margin-top: 100px;

    h3 {
      font-family: "Raleway";
      font-style: italic;
      font-weight: 600;
      font-size: 16px;
      line-height: 180%;
      color: $LumiGray;
      text-align: center;
    }

    #subscribe-form {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: auto;
      margin-top: 30px;

      .label {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
        color: $LumiBlue;
        margin-right: 20px;
      }

      input[type="text"] {
        width: 400px;
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: $Graphite;
        padding: 5px 15px 5px 15px;
        border-radius: 5px;
        border: 2px solid lightgray;
        outline: none !important;
      }

      button {
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: $LumiBodyText;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 25px 20px 25px;
        border-radius: 30px 0px 30px 0px;
        cursor: pointer;
        background-color: $LumiBlue;
        color: white;
        border: none;
        outline: none !important;
      }
    }
  }

  #insurance-and-medicare {
    margin-top: 100px;
    height: 560px;
    background-color: #fff7eb;
    border-radius: 180px 0px 180px 0px;
    padding-top: 1px;

    h3 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      color: $LumiBlue;
      text-align: center;
      margin-top: 100px;
      display: block;
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      margin-top: 60px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;

      .dash {
        width: 79.47px;
        height: 0.33px;
        opacity: 0.5;
        border: 2px solid #455b66;
        border-radius: 4px;
      }

      img {
        width: 90px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 60px;

      .button-outline {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 175%;
        letter-spacing: 0.04em;
        color: $LumiRose;
        border: 3px solid $LumiRose;
        padding: 10px 20px 10px 20px;
        border-radius: 30px 0px 30px 0px;
      }

      .button-fill {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 175%;
        letter-spacing: 0.04em;
        color: white;
        border: 3px solid $LumiRose;
        background-color: $LumiRose;
        padding: 10px 20px 10px 20px;
        border-radius: 30px 0px 30px 0px;
      }
    }

    h4 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $LumiBodyText;
      text-align: center;
      margin-top: 40px;
    }
  }

  .specialization-star {
    margin-top: 150px;

    .content {
      width: 80%;
      margin: auto;

      .push-left {
        transform: translate(-130px, 30px);
      }

      .push-right {
        transform: translate(130px, 30px);
      }

      .middle {
        margin-top: 20px !important;
      }

      .star-row {
        text-align: center;
        margin-top: 80px;

        .star-middle {
          display: inline-block;
          width: 140px;
          height: 150px;

          img {
            width: 148px;
          }
        }
        .star-item {
          display: inline-block;
          width: 35%;
          text-align: center;
          vertical-align: top;

          h2 {
            width: 80%;
            text-align: center;
            display: block;
            margin: auto;
          }
        }

        .rose {
          color: $LumiRose;
        }

        .orange {
          color: $LumiOrange;
        }

        .blue {
          color: $LumiBlue;
        }

        .yellow {
          color: $LumiYellow;
        }

        .pink {
          color: $LumiPink;
        }

        .lightgreen {
          color: $LumiLightGreen;
        }

        h1 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
          text-align: center;
        }

        h2 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 160%;
          text-align: center;
          letter-spacing: 0.02em;
        }
      }
    }
  }

  .clear-both {
    clear: both;
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  body {
    overflow-x: hidden;
  }
  #homepage {
    padding-left: 0px;
    padding-right: 0px;
    #banner {
      position: relative;
      display: block;
      height: 663px;
      background-image: conic-gradient(
          from 270deg at 100% 62%,
          rgba(0, 0, 0, 0.5098039216) 8deg,
          rgba(0, 0, 0, 0) 19deg,
          rgba(0, 0, 0, 0.5098039216) 359.96deg,
          rgba(0, 0, 0, 0.5098039216) 360deg
        ),
        linear-gradient(0deg, rgba(37, 50, 56, 0.07), rgba(37, 50, 56, 0.07)),
        url(../../images/homepage_banner_mobile.png);

      #content {
        width: 90%;
        color: white;
        margin: auto;
        position: absolute;
        bottom: 40px;
        left: 20px;
        right: 20px;

        h1 {
          font-family: "Jost";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 100%;
        }

        h3 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          margin-top: 20px;
          letter-spacing: 1px;
          line-height: 160%;
        }

        a {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: $LumiBodyText;
          margin-top: 20px;
          letter-spacing: 1px;
          display: inline-block;
          padding: 10px 15px 10px 15px;
          border-radius: 20px 0px 20px 0px;
          cursor: pointer;
          background-color: $LumiRose;
          color: white;
        }
      }
    }

    #specialties {
      margin-top: 30px;
      height: auto;
      position: relative;

      ul {
        display: block;
        width: 100%;
        margin: auto;
        margin-top: 50px;
        padding: 0;
        position: static;

        li {
          display: block;
          float: left;
          width: calc(50% - 30px);
          height: 170px;
          margin-left: 20px;
          margin-bottom: 20px;
          position: static;

          img {
            display: none;
          }

          .name {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            text-align: center;
            color: white;
            border-radius: 0px 32px;
            z-index: 1;
            position: static;
            padding: 10px;
          }

          .rose {
            background-color: $LumiRose;
          }

          .orange {
            background-color: $LumiOrange;
          }

          .blue {
            background-color: $LumiBlue;
          }

          .yellow {
            background-color: $LumiYellow;
          }

          .pink {
            background-color: $LumiPink;
          }

          .lightgreen {
            background-color: $LumiLightGreen;
          }
        }
      }
    }

    #how-it-works {
      margin-top: 0px;
      border-radius: 120px 0px 0px 0px;

      h1 {
        margin-top: 10px;
        font-size: 36px;
        margin-bottom: 0px;
      }

      .how-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0px;

        .how-item {
          width: 90%;
          margin-top: 50px;

          .icon {
            width: 120px;
          }
        }
      }
    }

    #get-matched {
      margin-top: 100px;
      text-align: center;

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 54px;
      }

      p {
        width: 90%;
      }
    }

    #practitioners-wrap {
      margin-top: 50px;
      border-radius: 0px 120px 0px 0px;
      gap: 20px;
      padding-bottom: 50px;

      #practitioner-box > .row > .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      h1 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 120%;
        text-align: center;
        color: $LumiBlue;
      }

      p {
        display: block;
        width: 95%;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #455b66;
      }

      .asterisk {
        width: 50px;
      }

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: $LumiBlue;
        text-align: center;
      }

      .practitioner-card-container {
        background-image: url("../../images/bg_border.png");
        background-size: 100% 100%;
        border-radius: 33px 0px;
        padding: 2px;
        margin-bottom: 20px;

        .practitioner-card {
          padding-top: 20px;
          padding-bottom: 20px;
          background-color: white;
          border-radius: 32px 0px;

          .col-8 {
            flex: 0 0 100%;
            max-width: 100%;
          }

          img {
            width: 100%;
            border-radius: 4px;
          }

          .top-badge {
            width: 90%;
            font-family: "Raleway";
            font-weight: normal;
            font-weight: 700;
            letter-spacing: 0.5px;
            font-size: 9px;
            background-color: $LumiRose;
            color: white;
            border-radius: 4px;
            padding: 4px;
            text-align: center;
            margin: auto;
            margin-top: -12px;
            position: relative;
            z-index: 1;
          }

          .name {
            font-family: "Jost";
            font-weight: normal;
            font-weight: 500;
            font-size: 24px;
            color: $LumiRose;
          }

          .position {
            font-family: "Raleway";
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
          }

          .message {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            margin-top: 20px;
          }

          .rate {
            width: auto;
            margin: auto;
            margin-top: 20px;
            text-align: center;

            .figure {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: black;
            }

            .persession {
              font-family: "Raleway";
              font-style: italic;
              font-weight: 300;
              font-size: 16px;
              color: $Graphite;
              margin-left: 20px;
            }
          }
        }
      }

      a {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 28px;
        gap: 10px;
        background: $LumiOrange;
        border-radius: 30px 0px;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        margin: auto;
      }
    }

    #benefits {
      display: none;
    }

    #benefits-mobile {
      margin-top: 50px;
      display: block;

      #phone-container {
        position: relative;
        height: auto;
        background-image: url("../../images/bg_circles.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0px 200px;
        overflow: hidden;

        #phones {
          width: 120%;
          margin-left: -10%;
        }

        #bg-circles {
          width: 100%;
          z-index: -1;
          position: absolute;
          margin-top: 20%;
        }

        #content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .content-box {
            width: 90%;
            margin: auto;
            margin-top: 30px;

            h1 {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              color: white;
            }

            .list {
              display: block;
              margin: 0;
              padding: 0;
              margin-top: 00px;
              margin-bottom: 40px;

              li {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                margin-top: 20px;

                .num {
                  width: 50px;
                  height: 50px;
                  font-family: "Raleway";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  text-align: center;
                  color: white;
                  border: 4px solid white;
                  border-radius: 1000px;
                  padding-top: 3px;
                  margin-right: 20px;
                  display: inline-block;
                  vertical-align: middle;
                }

                .text {
                  width: 80%;
                  font-family: "Raleway";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 28px;
                  color: #ffffff;
                  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    .specialization-star {
      margin-top: 100px;

      .content {
        width: 80%;
        margin: auto;

        .push-left {
          transform: translate(0px, 0px);
        }

        .push-right {
          transform: translate(0px, 0px);
        }

        .middle {
          margin-top: 0px !important;
          display: block;
        }

        .star-row {
          text-align: center;
          margin-top: 0px;

          .star-middle {
            display: inline-block;
            width: 140px;
            height: 80px;
            display: none;

            img {
              width: 80px;
            }
          }

          .star-middle:first-child {
            display: none;
          }

          .star-item {
            display: inline-block;
            width: 100%;
            text-align: center;
            vertical-align: top;
            margin-bottom: 80px;

            h2 {
              width: 80%;
              text-align: center;
              display: block;
              margin: auto;
            }
          }

          .rose {
            color: $LumiRose;
          }

          .orange {
            color: $LumiOrange;
          }

          .blue {
            color: $LumiBlue;
          }

          .yellow {
            color: $LumiYellow;
          }

          .pink {
            color: $LumiPink;
          }

          .lightgreen {
            color: $LumiLightGreen;
          }

          h1 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
          }

          h2 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            letter-spacing: 0.02em;
          }
        }

        .star-row:nth-child(2) {
          .star-middle {
            display: inline-block;
            width: 140px;
            height: 80px;
            margin-bottom: 80px;

            img {
              width: 80px;
            }
          }
        }
      }
    }

    #goodreads {
      > .row > .col-3,
      .col-9 {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0px;
        padding: 0px;
      }

      .good-reads-script-container {
        .good-reads-script {
          max-width: 300px;
          margin-bottom: 20px;
        }
      }

      #articles {
        display: block;
        width: 95%;
        .article {
          width: 100%;
          margin-top: 50px;

          .article-card {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    #emailform {
      width: 90%;
      margin: auto;
      margin-top: 100px;

      h3 {
        font-family: "Raleway";
        font-style: italic;
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        color: $LumiGray;
        text-align: center;
      }

      #subscribe-form {
        width: 90%;
        display: block;
        text-align: center;
        margin: auto;
        margin-top: 30px;

        .label {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 130%;
          color: $LumiBlue;
          margin-right: 20px;
          display: inline-block;
          margin-bottom: 20px;
        }

        input[type="text"] {
          width: 100%;
          height: 50px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: $Graphite;
          padding: 5px 15px 5px 15px;
          border-radius: 5px;
          border: 2px solid lightgray;
          outline: none !important;
          margin-bottom: 20px;
        }

        button {
          width: 100%;
          height: 50px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: $LumiBodyText;
          letter-spacing: 1px;
          display: inline-block;
          padding: 12px 25px 20px 25px;
          border-radius: 30px 0px 30px 0px;
          cursor: pointer;
          background-color: $LumiBlue;
          color: white;
          border: none;
          outline: none !important;
        }
      }
    }

    #insurance-and-medicare {
      margin-top: 100px;
      height: auto;
      background-color: #fff7eb;
      border-radius: 120px 0px 120px 0px;
      padding-top: 1px;
      padding-bottom: 50px;

      h3 {
        width: 90%;
        margin: auto;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        color: $LumiBlue;
        text-align: center;
        margin-top: 100px;
        display: block;
      }

      .links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 40px;
        margin-top: 60px;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;

        div:nth-child(1) {
          width: 100%;
          text-align: center;
        }

        div:nth-child(2) {
          width: 100%;
          text-align: center;

          .dash {
            width: 30%;
          }
        }

        div {
          width: 30%;
          text-align: center;
        }

        .dash {
          width: 40%;
          height: 0.33px;
          margin: auto;
          opacity: 0.5;
          border: 2px solid #455b66;
          border-radius: 4px;
        }

        img {
          width: 100%;
          max-width: 120px;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 60px;
        flex-wrap: wrap;

        .button-outline {
          width: 90%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 175%;
          letter-spacing: 0.04em;
          color: $LumiRose;
          border: 3px solid $LumiRose;
          padding: 10px 20px 10px 20px;
          border-radius: 30px 0px 30px 0px;
        }

        .button-fill {
          width: 90%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 175%;
          letter-spacing: 0.04em;
          color: white;
          border: 3px solid $LumiRose;
          background-color: $LumiRose;
          padding: 10px 20px 10px 20px;
          border-radius: 30px 0px 30px 0px;
        }
      }

      h4 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-decoration-line: underline;
        color: $LumiBodyText;
        text-align: center;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        display: block;
        width: 90%;
        text-align: left;
      }
    }
  }
}
