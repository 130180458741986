.practitioner-signup {
  .banner {
    height: 950px;
    margin-left: $SidePadding;
    margin-right: $SidePadding;
    margin-top: 30px;
    background-image: url("../../images/bg_practitioner_signup.png");
    background-size: 100% 100%;
    border-radius: 0px 0px 352px 0px;

    .error {
      color: $LumiRose;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 893px;
      margin-top: 30px;

      .signup-form {
        width: 80%;
        background-color: white;
        border-radius: 0px 83px 0px 0px;
        padding-top: 40px;
        padding-bottom: 40px;

        h3 {
          font-family: "Jost";
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 110%;
          color: $LumiBlue;
          text-align: center;
          margin-bottom: 50px;
        }

        .fullname-container {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          text-align: left;

          .labels {
            margin: 0;
          }

          input[type="text"] {
            width: 100%;
          }
        }

        .labels {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: $Graphite;
          width: 70%;
          margin-left: auto;
          margin-right: auto;
        }

        input[type="text"],
        select {
          width: 70%;
          height: 50px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: $Graphite;
          padding: 5px 15px 5px 15px;
          border-radius: 5px;
          border: 2px solid lightgray;
          outline: none !important;
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
          display: block;
        }

        .password {
          width: 100% !important;
          height: 50px !important;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: $Graphite;
          padding: 5px 15px 5px 15px;
          border-radius: 5px;
          border: 2px solid lightgray;
          outline: none !important;
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
          display: block;
        }

        .password-wrapper {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          position: relative;

          .eye {
            width: 34px;
            position: absolute;
            right: 15px;
            top: 12px;
            cursor: pointer;
          }
        }

        button {
          width: 70%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: white;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          text-align: center;
          padding: 20px 25px 20px 25px;
          border: none;
          border-radius: 30px 0px 30px 0px;
          background-color: $LumiYellow;
          margin-top: 50px;
          margin-left: auto;
          margin-right: auto;
          outline-color: transparent;
          display: block;
        }

        .terms {
          width: 90%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          text-align: center;
          color: #90a4af;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;

          a {
            color: $Graphite;
          }
        }

        h5 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: $Graphite;
          text-align: center;
          margin-top: 20px;

          a {
            cursor: pointer;
            color: $LumiRose;

            :hover {
              color: $LumiRose;
            }
          }
        }

        .required {
          font-weight: bold;
          color: #d62d30;
        }
      }
    }

    .bullets {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 110%;
        color: #ffffff;
      }

      h5 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #ffffff;
      }

      ul {
        margin-top: 30px;
        margin-bottom: 50px;
        li {
          display: block;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          margin-top: 16px;

          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .practitioner-signup {
    .banner {
      height: auto;
      margin-left: $SidePadding;
      margin-right: $SidePadding;
      margin-top: 30px;
      background-image: none;
      background-size: 100% 100%;
      border-radius: 0px 0px 352px 0px;

      .error {
        color: $LumiRose;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        margin-top: 20px;

        .signup-form {
          width: 100%;
          background-color: white;
          border-radius: 0px 83px 0px 0px;
          padding-top: 0px;
          padding-bottom: 40px;

          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0px;
            padding-right: 0px;
          }

          h3 {
            font-family: "Jost";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 110%;
            color: $LumiBlue;
            text-align: center;
            margin-bottom: 50px;
          }

          .fullname-container {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: left;

            .labels {
              margin: 0;
            }

            input[type="text"] {
              width: 100%;
            }
          }

          .labels {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: $Graphite;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }

          input[type="text"],
          select {
            width: 100%;
            height: 50px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $Graphite;
            padding: 5px 15px 5px 15px;
            border-radius: 5px;
            border: 2px solid lightgray;
            outline: none !important;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
            display: block;
          }

          .password {
            width: 100% !important;
            height: 50px !important;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $Graphite;
            padding: 5px 15px 5px 15px;
            border-radius: 5px;
            border: 2px solid lightgray;
            outline: none !important;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
            display: block;
          }

          .password-wrapper {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            .eye {
              width: 34px;
              position: absolute;
              right: 15px;
              top: 12px;
              cursor: pointer;
            }
          }

          button {
            width: 100%;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: white;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            text-align: center;
            padding: 20px 25px 20px 25px;
            border: none;
            border-radius: 30px 0px 30px 0px;
            background-color: $LumiYellow;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;
            outline-color: transparent;
            display: block;
          }

          .terms {
            width: 90%;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: #90a4af;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;

            a {
              color: $Graphite;
            }
          }

          h5 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: $Graphite;
            text-align: center;
            margin-top: 20px;

            a {
              cursor: pointer;
              color: $LumiRose;

              :hover {
                color: $LumiRose;
              }
            }
          }

          .required {
            font-weight: bold;
            color: #d62d30;
          }
        }
      }

      .bullets {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background-image: url("../../images/bg_practitioner_signup.png");
        background-size: cover;
        background-position: center;
        border-radius: 0px 0px 128px 0px;
        padding-bottom: 30px;

        h3 {
          font-family: "Jost";
          font-style: normal;
          font-weight: 500;
          font-size: 35px;
          line-height: 110%;
          color: #ffffff;
          margin-top: 50px;
          text-align: center;
        }

        h5 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
        }

        ul {
          margin-top: 30px;
          margin-bottom: 50px;
          padding: 0px;
          li {
            img {
              width: 24px;
              height: 24px;
              margin-top: 1px;
              margin-right: 10px;
            }
            display: flex;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            margin-top: 16px;
          }
        }
      }
    }
  }
}
