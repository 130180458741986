.dashboard-sidebar {
  width: 100%;

  .profile-photo {
    width: 160px;
    height: 160px;
    border: 4px solid #fdbc5b;
    border-radius: 120px;
    display: block;
    margin: auto;
    margin-top: 80px;
  }

  .profile-name {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: $LumiYellow;
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 80px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  li {
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style: none;
    position: relative;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: $LumiBodyText;
      border-radius: 0px 32px 0px 0px;
      text-decoration: none;

      span {
        display: block;
      }

      .sidebar-navigation-icon {
        width: 32px;
        margin-right: 20px;
        margin-left: 20px;
      }

      .sidebar-navigation-asterisk {
        width: 32px;
        margin-right: 20px;
        position: absolute;
        right: 0px;
        filter: brightness(1000%);
      }
    }

    a.active {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      background-color: $LumiRose;
      color: white;
      border-radius: 0px 32px 0px 0px;

      .sidebar-navigation-icon {
        filter: brightness(1000%);
      }

      .sidebar-navigation-asterisk {
        width: 32px;
        margin-right: 20px;
        position: absolute;
        right: 0px;
        filter: brightness(1000%);
      }
    }
  }

  .logout {
    margin-top: 50px;
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .dashboard-sidebar {
    width: 100%;

    .sidebar-navigation {
      display: block;
      overflow: hidden;
      position: absolute;
      left: 0px;
      top: 86px;
      padding-top: 0px;
      background-color: white;
      width: 100%;
      max-height: 0;
      z-index: 9;
      border-bottom: none;
      transition: all 0.7s ease;
    }

    .sn-open {
      display: block;
      max-height: 1000px;
      top: 86px;
      border-bottom: 1px solid #a1a1a1;
      padding-top: 10px;
    }

    .mobile-header {
      display: flex;
      justify-content: space-between;

      .lumi-logo {
        width: 200px;
        margin-left: 15px;
        margin-top: 10px;
      }

      #hamburger {
        transform: scale(0.8);
        margin-top: 20px;
        margin-right: 10px;
        #menuToggle {
          display: block;
          position: relative;
          z-index: 1;
          -webkit-user-select: none;
          user-select: none;
        }

        #menuToggle input {
          display: block;
          width: 40px;
          height: 32px;
          position: absolute;
          top: -7px;
          left: -5px;

          cursor: pointer;

          opacity: 0;
          z-index: 2;

          -webkit-touch-callout: none;
        }

        #menuToggle span {
          display: block;
          width: 33px;
          height: 4px;
          margin-bottom: 5px;
          position: relative;

          background: $LumiOrange;
          border-radius: 3px;

          z-index: 1;

          transform-origin: 4px 0px;

          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        }

        #menuToggle span:first-child {
          transform-origin: 0% 0%;
        }

        #menuToggle span:nth-last-child(2) {
          transform-origin: 0% 100%;
        }

        #menuToggle input:checked ~ span {
          opacity: 1;
          transform: rotate(45deg) translate(-2px, -1px);
          background: $LumiOrange;
        }

        #menuToggle input:checked ~ span:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        #menuToggle input:checked ~ span:nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }
    }

    .mobile-sub-header {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .profile-photo {
        width: 50px;
        height: 50px;
        margin-top: 0px;
        border: 2px solid #fdbc5b;
        margin-left: 10px;
        margin-right: 10px;
      }

      .pill-container {
        width: calc(100% - 20px);
        margin-right: 10px;
        text-align: right;
        .datetime-pill {
          margin-top: 10px;
          width: 280px;
          padding: 5px;
          font-size: 13px;
          justify-content: space-around;

          .date {
            margin-right: 0px;
            text-align: center;
          }

          .day {
            margin-right: 0px;
            text-align: center;
          }
        }
      }
    }
  }
}
