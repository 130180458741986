@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";

$custom-colors: (
  "lumirose": $LumiRose,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

//Import original bootstrap after custome styles to apply changes.
@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.btn-lumirose,
.btn-secondary {
  border-radius: 10px 0px 10px 0px;
}

.modal-content {
  border: none;
}

.modal-header,
.modal-body {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}
