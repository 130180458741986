.admin-practitioner-profile-page {
  .profile-page {
    width: 65%;
    margin: auto;
    .profile-header {
      height: 240px;
      background-image: url("../../images/profile_banner_bg.png");
      border-radius: 64px 0px;
      margin-top: 50px;
      display: flex;
      .photo {
        width: 245px;
        height: 245px;
        border-radius: 1000px;
        border: 4px solid #fdbc5b;
        margin-left: 30px;
        transform: translate(0px, 30px);
      }

      .details {
        width: calc(100% - 270px);
        margin-left: 20px;
        margin-top: 60px;

        h1 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 47px;
          letter-spacing: 0.02em;
          color: #ffffff;
        }

        h2 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
        }

        .location {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          display: flex;
          margin-top: 30px;

          img {
            width: 16px;
            transform: translate(0px, -3px);
            margin-right: 10px;
          }
        }
      }
    }

    .profile-body {
      margin-top: 50px;
      display: flex;
      .left {
        width: 300px;
        padding-top: 30px;

        .reviews-link {
          text-align: center;
          color: $LumiGray;
          display: block;
        }

        .sessions-count {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 175%;
          color: $LumiBodyText;
          text-align: center;
          margin-top: 40px;
        }

        .book-now {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px 12px 26px;
          gap: 24px;
          outline: none;
          border: none;

          background: #69c1be;
          border-radius: 32px 0px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;
          color: #ffffff;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          margin: auto;
          margin-top: 15px;
        }

        .note {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #35505c;
          display: block;
          text-align: center;
          margin-top: 50px;
        }

        .send-message {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px 12px 26px;
          gap: 24px;
          outline: none;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          border: 1px solid #69c1be;

          background: white;
          border-radius: 32px 0px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 175%;
          text-align: right;
          letter-spacing: 0.04em;
          color: #69c1be;
          margin: auto;
          margin-top: 15px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      .right {
        width: calc(100% - 300px);
        padding-left: 30px;
        select {
          width: 100%;
          height: 50px;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: $Graphite;
          padding: 5px 15px 5px 15px;
          border-radius: 5px;
          border: 2px solid lightgray;
          outline: none !important;
          margin-left: auto;
          margin-right: auto;
          margin-top: 15px;
          display: block;
        }
        p {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 0.02em;
          color: $LumiBodyText;
          margin-top: 20px;
          margin-bottom: 40px;
          text-transform: capitalize;
        }
        .about-me {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          text-transform: capitalize;
          color: $LumiOrange;
        }
        .specialties, .role {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          // color: $LumiPink;
          color: $LumiOrange;
        }
        .right-one {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          color: $LumiYellow;
        }

        .separator {
          width: 100%;
          height: 1px;
          background-color: #dedede;
        }

        .membership-medicare {
          display: flex;
          padding-top: 70px;

          .icons {
            img {
              float: left;
              margin-right: 10px;
              margin-bottom: 10px;
              width: 100px;
            }
          }

          .memberships-left {
            width: 50%;
            h5 {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 23px;
              color: #000000;
            }
          }
          .memberships-right {
            width: 50%;
          }
        }
      }
    }
  }
  .quote-box {
    margin-top: 40px;
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .practitioner-profile-page {
    .profile-page {
      width: 100%;
      margin: auto;
      .profile-header {
        height: auto;
        min-height: 280px;
        background-image: url("../../images/bg_signup.png");
        background-size: cover;
        background-position: left;
        border-radius: 0px;
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        .photo {
          width: 180px;
          height: 180px;
          border-radius: 1000px;
          border: 4px solid #fdbc5b;
          transform: translate(0px, 70px);
          margin: auto;

          -webkit-box-ordinal-group: 2;
          -moz-box-ordinal-group: 2;
          -ms-flex-order: 2;
          -webkit-order: 2;
          order: 2;
        }

        .details {
          width: 100%;
          margin-left: 0px;
          margin-top: 0px;
          margin-bottom: 20px;
          transform: translate(0px, 70px);

          -webkit-box-ordinal-group: 1;
          -moz-box-ordinal-group: 1;
          -ms-flex-order: 1;
          -webkit-order: 1;
          order: 1;

          h1 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 47px;
            letter-spacing: 0.02em;
            color: #ffffff;
          }

          h2 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
          }

          .location {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            display: flex;
            margin-top: 30px;
            text-align: center;
            justify-content: center;
            align-items: center;

            img {
              width: 16px;
              transform: translate(0px, 0px);
              margin-right: 10px;
            }
          }
        }
      }

      .profile-body {
        margin-top: 120px;
        display: flex;
        flex-wrap: wrap;
        .left {
          width: 100%;

          .reviews-link {
            text-align: center;
            color: $LumiGray;
            display: block;
          }

          .sessions-count {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 175%;
            color: $LumiBodyText;
            text-align: center;
            margin-top: 40px;
          }

          .book-now {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px 12px 26px;
            gap: 24px;
            outline: none;
            border: none;

            background: #69c1be;
            border-radius: 32px 0px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            color: #ffffff;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            margin: auto;
            margin-top: 15px;
          }

          .note {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 180%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #35505c;
            display: block;
            text-align: center;
            margin-top: 50px;
          }

          .send-message {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px 12px 26px;
            gap: 24px;
            outline: none;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            border: 1px solid #69c1be;

            background: white;
            border-radius: 32px 0px;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 175%;
            text-align: right;
            letter-spacing: 0.04em;
            color: #69c1be;
            margin: auto;
            margin-top: 15px;
            padding-left: 40px;
            padding-right: 40px;
          }
        }

        .right {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 50px;
          p {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.02em;
            color: $LumiBodyText;
            margin-top: 30px;
            margin-bottom: 50px;
          }
          .about-me {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            font-size: 20px;
            line-height: 23px;
            color: $LumiOrange;
          }
          .specialties {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: $LumiPink;
          }
          .right-one {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: $LumiYellow;
          }

          .separator {
            width: 100%;
            height: 1px;
            background-color: #dedede;
            display: none;
          }

          .membership-medicare {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            .icons {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              img {
                margin-right: 10px;
                margin-bottom: 10px;
                width: 100px;
              }
            }

            .memberships-left {
              width: 95%;
              h5 {
                font-family: "Raleway";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: $LumiOrange;
                text-align: center;
              }
            }
            .memberships-right {
              width: 95%;
              h5 {
                font-family: "Raleway";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: $LumiOrange;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .quote-box {
      margin-top: 40px;
    }
  }
}

.save-button {
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: $LumiBodyText;
  margin-top: 20px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 15px 10px 15px;
  border-radius: 20px 0px 20px 0px;
  cursor: pointer;
  background-color: $LumiRose;
  color: white;
  outline: none;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
}

.update-button {
  
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 2px 16px;
  height: 32px;
  min-width: 60px;
  min-height: 32px;
  border: none;
  color: #fff;
  background-color: #4f545c;
  transition: background-color .17s ease,color .17s ease;

}
