$LumiRose: #ec7363;
$LumiOrange: #f48256;
$LumiBlue: #69c1be;
$LumiYellow: #fdbc5b;
$LumiPink: #f8ab9b;
$LumiLightGreen: #b4e0df;
$LumiGray: #455b66;
$LumiBodyText: #35505c;
$Graphite: #253238;

$ContainerPadding: 10px 30px;
$SidePadding: 30px;

$MobileViewThreshold: 1024px;
