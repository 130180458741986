.practitioner-calendar {
  padding: 50px 30px;
  .title-bar {
    display: flex;
    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
    }
  }

  .react-datepicker {
    font-family: "Raleway";
    font-size: 16px;
    width: 320px;

    .react-datepicker__navigation-icon--previous::before {
      right: -7px;
      top: 15px;
    }

    .react-datepicker__navigation-icon--next::before {
      right: -7px;
      top: 15px;
    }

    .react-datepicker__month-text--keyboard-selected {
      background-color: $LumiPink;
      color: white;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
      display: inline-block;
      margin: 5px;
      width: auto;
      padding: 2px 5px;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month-container {
      float: left;
      width: 318px;
    }
  }

  .react-datepicker__input-container {
    height: 40px;
    input {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 25px;
      gap: 20px;
      background: #b4e0df;
      border-radius: 0px 24px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: $LumiGray;
      border: none !important;
      outline: none !important;
      margin-bottom: 20px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }

    .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle::after {
      display: none;
    }
  }

  .calendar-container {
    margin-top: 20px;
    .react-calendar {
      width: 100%;
      border: 1px solid #d9d9d9;

      .react-calendar__month-view__weekdays {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.02em;
        color: $LumiGray;
        text-transform: capitalize;
        background-color: gray;

        .react-calendar__month-view__weekdays__weekday {
          text-align: left;
          background-color: #ffefd6;
          border: 1px solid #d9d9d9;
          border-left: none;
          border-top: none;

          &:last-child {
            border: 1px solid #d9d9d9;
            border-left: none;
            border-top: none;
            border-right: none;
          }

          abbr {
            cursor: default;
            text-decoration: none;
          }
        }
      }

      .react-calendar__month-view__days {
        button {
          border: 1px solid #d9d9d9;
          border-left: none;
          border-top: none;
          text-align: left;
          padding: 0px;
          height: auto;

          &:nth-child(7n) {
            border: 1px solid #d9d9d9;
            border-left: none;
            border-top: none;
            border-right: none;
          }

          abbr {
            display: none;
          }
        }

        .tile-content {
          min-height: 200px;
          padding: 10px;

          .date-number {
            text-align: left;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: #35505c;
          }

          .unavailable-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 26px);
            span {
              display: block;
            }
          }

          .sessions {
            .session {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 8px 16px;
              width: 100%;
              height: 46px;
              margin-top: 7px;
              border-radius: 16px 0px;
              color: #35505c;
              cursor: pointer;

              .name {
                font-family: "Raleway";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.02em;
              }

              .time {
                font-family: "Raleway";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.02em;
              }
            }

            .session:nth-child(1),
            .session:nth-child(5n) {
              background: #b4e0df;
            }
            .session:nth-child(2),
            .session:nth-child(6n) {
              background: #fcd5cd;
            }

            .session:nth-child(3),
            .session:nth-child(7n) {
              background: #fac1ab;
            }

            .session:nth-child(4),
            .session:nth-child(8n) {
              background: #fedead;
            }
          }
        }

        .unavailable {
          background-image: url("../../images/static.png");
          height: 100px;
          background-size: 90%;

          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.02em;
          height: 100%;
          color: $LumiRose;
        }
      }

      .react-calendar__tile {
        &:hover {
          background-color: white;
          cursor: default;
        }
      }
      .react-calendar__tile--now,
      .react-calendar__tile--active {
        background-color: white;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.4;
        .date-number {
          opacity: 0.4;
        }
      }
    }
  }

  .no-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    transform: translate(-150px);

    span {
      display: block;
      color: $LumiRose;
      border: 1px solid $LumiRose;
      padding: 20px 30px;
      border-radius: 20px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.06em;
      color: $LumiRose;

      a {
        text-decoration: underline;
        color: $LumiRose;
      }

      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}
