.datetime-pill {
  display: inline-flex;
  font-family: "Raleway";
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid $LumiYellow;
  border-radius: 100px;
  padding: 15px 25px;

  .date {
    margin-right: 40px;
    font-weight: 700;
  }

  .day {
    margin-left: 0px;
    margin-right: 40px;
    font-weight: 600;
  }

  .time {
    font-weight: 700;
  }
}
