.practitioners-page {
  h1 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 54px;
    line-height: 120%;
    text-align: center;
    color: $LumiBlue;
    padding-top: 100px;
  }

  h3 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.02em;
    text-align: center;
    color: $Graphite;
  }

  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: $LumiBlue;
    text-align: center;
    margin-top: 100px;
  }

  .filters-container {
    .filters {
      padding-left: $SidePadding;
      padding-right: $SidePadding;
      margin: auto;
      margin-top: 30px;

      .refine-search {
        display: none;
      }
    }
    .list {
      padding-left: $SidePadding;
      padding-right: $SidePadding;
      margin: auto;
      margin-top: 40px;
    }
  }

  .if-you-may-ask {
    width: 80%;
    margin: auto;
    h2 {
      text-align: left;
    }

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      li {
        margin-top: 50px;
        .question {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiOrange;
          margin-top: 10px;
        }
        .answer {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: #253238;
          margin-top: 10px;
        }
      }
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .practitioners-page {
    h1 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 54px;
      line-height: 120%;
      text-align: center;
      color: $LumiBlue;
      padding-top: 100px;
    }

    h3 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: 0.02em;
      text-align: center;
      color: $Graphite;
    }

    h2 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: $LumiBlue;
      text-align: center;
      margin-top: 100px;
    }

    .filters-container {
      .filters {
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
        margin-top: 30px;

        .refine-search {
          display: block;

          font-family: "Raleway";
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: #455b66;
          text-align: center;

          img {
            width: 16px;
            margin-top: -5px;
            margin-left: 10px;
            transition: all 0.3s;
          }
        }

        .open {
          img {
            transform: rotate(90deg);
            transition: all 0.3s;
          }
        }
      }
      .list {
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
        margin-top: 40px;
      }

      .col-11 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .if-you-may-ask {
      width: 90%;
      h2 {
        text-align: center;
      }
    }
  }
}
