.dashboard-header {
  position: relative;
  .logo {
    width: 300px;
    display: block;
    margin: auto;
    transform: translate(-150px);
    margin-top: 50px;
  }

  //Custom style for datetime-pill
  .datetime-pill {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 15px;
    margin-right: 20px;
  }

  @media only screen and (max-width: $MobileViewThreshold) {
    .title-bar {
      .datetime-pill {
        display: none;
      }
    }
    .logo {
      display: none;
    }
  }
}
