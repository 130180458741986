.practitioners-list {
  .practitioner-card-border {
    background-image: url("../../images/bg_border.png");
    background-size: 100% 100%;
    border-radius: 32px 0px;
    position: relative;
    padding: 2px;
    margin-bottom: 20px;
    .practitioner-card {
      display: flex;
      position: relative;
      background: white;
      border-radius: 30px 0px;
      padding-left: 40px;
      padding-right: 0px;

      .photo-container {
        width: 200px;
        margin-top: 40px;
        margin-bottom: 40px;
        .photo {
          display: block;
          width: 150px;
          margin: auto;
          border-radius: 1000px;
        }

        .top-badge {
          width: 80px;
          font-family: "Raleway";
          font-weight: normal;
          font-weight: 700;
          letter-spacing: 0.5px;
          font-size: 10px;
          background-color: $LumiRose;
          color: white;
          border-radius: 4px;
          padding: 5px;
          text-align: center;
          margin: auto;
          margin-top: -15px;
          position: relative;
          z-index: 1;
        }

        .rating-widget {
          margin-top: 20px;
        }

        .reviews-link {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiGray;
          display: block;
          text-align: center;
        }
      }

      .more-info {
        margin-left: 20px;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        .name {
          font-family: "Jost";
          font-weight: normal;
          font-weight: 500;
          font-size: 24px;
          color: $LumiRose;
        }

        .position {
          font-family: "Raleway";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
        }

        .message {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiBodyText;
          margin-top: 20px;
        }

        .location {
          h3 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 160%;
            color: $Graphite;
            text-align: left;
            margin-top: 20px;
          }
          p {
            font-size: 13px;
          }
        }

        .specialties {
          h3 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 160%;
            color: $Graphite;
            text-align: left;
            margin-top: 20px;
          }

          ul {
            display: flex;
            padding: 0px;
            li {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 160%;
              color: $Graphite;
              display: block;
              padding: 0px;
              border: 1px gray solid;
              border-radius: 1000px;
              padding: 5px 15px;
              margin-right: 10px;
            }
          }
        }
      }

      .separator {
        opacity: 0.2;
        border: 1px solid $LumiBlue;
      }

      .booking-details {
        width: 30%;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;

        .client-age {
          background-color: #fad0cd !important;
          padding-left: 6px;
          padding-right: 6px;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 12px;

          display: block;
          width: 15%;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: black;
          text-align: center;
        }

        .rate {
          width: auto;
          margin-top: 20px;
          text-align: center;

          .figure {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: black;
          }

          .persession {
            font-family: "Raleway";
            font-style: italic;
            font-weight: 300;
            font-size: 16px;
            color: $Graphite;
            margin-left: 20px;
          }
        }
        .availability {
          display: block;
          text-align: center;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          color: black;
          margin-top: 20px;

          .when {
            color: $LumiRose;
          }
        }

        .book-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 20px 8px 24px;
          gap: 12px;
          width: 180px;
          height: 44px;
          background: $LumiBlue;
          border: 3px solid $LumiBlue;
          border-radius: 32px 0px;
          margin-top: 20px;

          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;
          color: white;
          text-decoration: none;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.practitioners-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .practitioner-card-border {
    background-image: url("../../images/bg_border.png");
    background-size: 100% 100%;
    border-radius: 32px 0px;
    position: relative;
    padding: 2px;
    margin-bottom: 20px;
    width: calc(50% - 20px);
    .practitioner-card {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      background: white;
      border-radius: 30px 0px;
      padding-left: 40px;
      padding-right: 0px;
      padding-bottom: 40px;

      .photo-container {
        width: 200px;
        margin-top: 40px;
        margin-bottom: 20px;
        .photo {
          display: block;
          width: 150px;
          margin: auto;
          border-radius: 1000px;
        }

        .top-badge {
          width: 80px;
          font-family: "Raleway";
          font-weight: normal;
          font-weight: 700;
          letter-spacing: 0.5px;
          font-size: 10px;
          background-color: $LumiRose;
          color: white;
          border-radius: 4px;
          padding: 5px;
          text-align: center;
          margin: auto;
          margin-top: -15px;
          position: relative;
          z-index: 1;
        }

        .rating-widget {
          margin-top: 20px;
        }

        .reviews-link {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiGray;
          display: block;
          text-align: center;
        }
      }

      .more-info {
        margin-left: 20px;
        width: calc(100% - 300px);
        margin-top: 40px;
        margin-bottom: 10px;
        .name {
          font-family: "Jost";
          font-weight: normal;
          font-weight: 500;
          font-size: 24px;
          color: $LumiRose;
        }

        .position {
          font-family: "Raleway";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
        }

        .message {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiBodyText;
          margin-top: 20px;
        }
      }

      .specialties {
        width: 100%;
        h3 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: $Graphite;
          text-align: left;
          margin-top: 10px;
        }

        ul {
          display: flex;
          padding: 0px;
          li {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 160%;
            color: $Graphite;
            display: block;
            padding: 0px;
            border: 1px gray solid;
            border-radius: 1000px;
            padding: 5px 15px;
            margin-right: 10px;
          }
        }
      }

      .separator {
        width: 95%;
        border-bottom: 1px solid $LumiYellow;
        margin-top: 10px;
      }

      .booking-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
        .left {
          width: 50%;
          .client-age {
            display: block;
            width: 100%;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: black;
            text-align: center;
          }

          .rate {
            width: auto;
            margin-top: 5px;
            text-align: center;

            .figure {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: black;
            }

            .persession {
              font-family: "Raleway";
              font-style: italic;
              font-weight: 300;
              font-size: 16px;
              color: $Graphite;
              margin-left: 5px;
            }
          }
          .availability {
            display: block;
            text-align: center;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 180%;
            color: black;
            margin-top: 5px;

            .when {
              color: $LumiRose;
            }
          }
        }
        .right {
          width: 50%;
          .book-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 20px 8px 24px;
            gap: 12px;
            width: 205px;
            height: 44px;
            background: $LumiBlue;
            border: 3px solid $LumiBlue;
            border-radius: 32px 0px;
            margin-top: 20px;

            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            color: white;
            text-decoration: none;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

.practitioners-mobile {
  display: flex;
  flex-wrap: wrap;
  .practitioner-card-border {
    background-image: url("../../images/bg_border.png");
    background-size: 100% 100%;
    border-radius: 32px 0px;
    position: relative;
    padding: 2px;
    margin-bottom: 20px;
    width: 100%;
    .practitioner-card {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      background: white;
      border-radius: 30px 0px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 15px;

      .photo-container {
        width: 128px;
        margin-top: 40px;
        margin-bottom: 20px;
        .photo {
          display: block;
          width: 120px;
          margin: auto;
          border-radius: 1000px;
        }

        .top-badge {
          width: 80px;
          font-family: "Raleway";
          font-weight: normal;
          font-weight: 700;
          letter-spacing: 0.5px;
          font-size: 10px;
          background-color: $LumiRose;
          color: white;
          border-radius: 4px;
          padding: 5px;
          text-align: center;
          margin: auto;
          margin-top: -15px;
          position: relative;
          z-index: 1;
        }

        .rating-widget {
          margin-top: 20px;
        }
      }

      .more-info {
        margin-left: 20px;
        width: calc(80% - 170px);
        margin-top: 40px;
        margin-bottom: 10px;
        .name {
          font-family: "Jost";
          font-weight: normal;
          font-weight: 500;
          font-size: 24px;
          color: $LumiRose;
          text-align: center;
        }

        .position {
          font-family: "Raleway";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
        }

        .reviews-link {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiGray;
          display: block;
          text-align: center;
        }

        .rating-widget {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 25px;
            margin: 4px;
          }
        }
      }

      .message {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiBodyText;
        margin-top: 20px;
      }

      .specialties {
        width: 100%;
        h3 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: $Graphite;
          text-align: left;
          margin-top: 10px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          padding: 0px;
          li {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 160%;
            color: $Graphite;
            display: block;
            padding: 0px;
            border: 1px gray solid;
            border-radius: 1000px;
            padding: 5px 15px;
          }
        }
      }

      .separator {
        width: 95%;
        border-bottom: 1px solid $LumiYellow;
        margin-top: 10px;
      }

      .booking-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
        .left {
          width: 50%;
          text-align: center;
          .client-age {
            display: inline-block;
            padding: 5px 15px;
            width: auto;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: black;
            text-align: center;
            background: rgba(248, 171, 155, 0.2);
            border-radius: 24px;
          }

          .rate {
            width: auto;
            margin-top: 5px;
            text-align: center;

            .figure {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: black;
            }

            .persession {
              display: block;
              font-family: "Raleway";
              font-style: italic;
              font-weight: 300;
              font-size: 16px;
              color: $Graphite;
              margin-left: 5px;
            }
          }
        }
        .right {
          width: 50%;

          .availability {
            display: block;
            text-align: center;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 180%;
            color: black;
            margin-top: 5px;

            .when {
              color: $LumiRose;
            }
          }

          .book-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 20px 8px 24px;
            gap: 12px;
            width: 90%;
            height: 44px;
            background: $LumiBlue;
            border: 3px solid $LumiBlue;
            border-radius: 32px 0px;
            margin-top: 20px;

            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 140%;
            color: white;
            text-decoration: none;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
