.get-started {

  .main-description {
    font-family: "Jost";
    font-style: normal;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    color: $LumiBlue;
    padding-top: 30px;
    color: #ec7363 !important;
  }


  .image-file-input {
    display: none;
  }

  .verification-form {
    width: 100%;

    .verification-form-label {
      color: $LumiBodyText;
      font-size: 16px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      line-height: 180%;
    }

    .verification-input {
      width: 100%;
      color: $LumiBodyText;
      font-size: 18px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 13px;
      margin-bottom: 20px;

      border-radius: 12px;
      border: 1px solid #b0bfc6;
      background: #fff;
      outline: none;
    }

    .verification-select__control--is-focused {
      border: none;
      outline: none;
      box-shadow: none;
      outline-color: transparent;
    }

    .verification-select__indicator-separator {
      opacity: 0;
    }

    .verification-select__control {
      width: 100%;
      color: $LumiBodyText;
      font-size: 18px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6px;
      margin-bottom: 20px;

      border-radius: 12px;
      border: 1px solid #b0bfc6;
      background: #fff;
      outline: none;
    }

    .verification-photo {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;

      color: $LumiBodyText;
      font-size: 16px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      line-height: 180%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }



      .verification-photo-img {
        width: 100%;
      }
    }

    .verification-form-left {
      float: left;
      width: calc(50% - 10px);
    }

    .verification-form-right {
      float: right;
      width: calc(50% - 10px);
    }
  }

  .required-legal-purpose {
    color: #69c1be !important;
    font-size: 22px !important;
    text-align: center;
  }

  

  .terms {
    width: 65%;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    // color: #90a4af;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    a {
      color: $Graphite;
    }

    .certification-input {
      width: 80%;
      color: $LumiBodyText;
      font-size: 18px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 13px;
      margin-bottom: 20px;
      height: 70px;
      border-radius: 12px;
      border: 1px solid #b0bfc6;
      background: #fff;
      outline: none;
    }

    
  }



  .form-wrapper {
    padding-left: 40%;
  }

  .input-wrapper {
    text-align: left;
    display: flex;

    .icon-globe {
      width: 20px;
      transform: translateY(3px);
      margin-right: 10px;
    }

    .timezone-select__control {
      width: auto;
      min-width: 150px;
      color: $LumiBodyText;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      outline: none;
    }

    .timezone-select__control--is-focused {
      border: none;
      outline: none;
      box-shadow: none;
      outline-color: transparent;
    }

    .timezone-select__indicator-separator {
      opacity: 0;
    }
  }

  h1 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    color: $LumiBlue;
    padding-top: 30px;
  }

  h3 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.02em;
    text-align: center;
    color: $Graphite;
  }

  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: $LumiBlue;
    text-align: center;
    margin-top: 100px;
  }

  .get-started-box {
    width: 60%;
    margin: auto;
    margin-top: 50px;
    background-image: url("../../images/bg_border.png");
    background-size: 100% 100%;
    border-radius: 96px 0px 0px 0px;
    position: relative;
    padding: 5px;
  }

  .get-started-box-content {
    background-color: white;
    border-radius: 92px 0px 0px 0px;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;

    .question {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      text-align: center;
      line-height: 120%;
      color: #444444;
      margin-right: 50px;
    }

    .description {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #ec7363;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .bottom-description {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: black;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .one-col-ul {
      padding: 0px;
      margin: 0px;

      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: auto;
        margin-bottom: 3px;
        align-items: center;
        padding: 18px 24px;
        gap: 16px;
        background-color: #fff5e6;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        color: $LumiBodyText;
        cursor: pointer;
      }
    }

    .uploadShow {
      display: block !important;
      font-family: "Raleway";
    }

    .uploadHide {
      display: none !important;
      font-family: "Raleway";
    }

    .two-col-ul {
      padding: 0px;
      margin: 0px;
      list-style-type: disc;
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      list-style-position: inside; //this is important addition

      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: auto;
        margin-bottom: 3px;
        align-items: center;
        padding: 18px 24px;
        gap: 16px;
        background-color: #fff5e6;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        color: $LumiBodyText;
        cursor: pointer;
      }
    }

    .two-col-ul-followup-questions {
      padding: 0px;
      margin: 0px;
      list-style-type: disc;
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      list-style-position: inside; //this is important addition

      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: auto;
        margin-bottom: 3px;
        align-items: center;
        padding: 18px 24px;
        gap: 16px;
        background-color: #E9F6F5;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        color: $LumiBodyText;
        cursor: pointer;
      }
    }

    .next {
      width: 100px;
      min-width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 12px 26px;
      gap: 24px;
      background: $LumiRose;
      border-radius: 32px 0px;
      margin: auto;
      margin-top: 50px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    .next-long {
      width: 100px;
      min-width: 350px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 12px 26px;
      gap: 24px;
      background: $LumiRose;
      border-radius: 32px 0px;
      margin: auto;
      margin-top: 50px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }

    .next-disable {
      width: 100px;
      min-width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px 12px 26px;
      gap: 24px;
      background: grey;
      border-radius: 32px 0px;
      margin: auto;
      margin-top: 50px;

      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      color: white;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }

  .practitioner-card {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 28px 0px;

    img {
      width: 100%;
      border-radius: 4px;
    }

    .top-badge {
      width: 60%;
      font-family: "Raleway";
      font-weight: normal;
      font-weight: 700;
      letter-spacing: 0.5px;
      font-size: 10px;
      background-color: $LumiRose;
      color: white;
      border-radius: 4px;
      padding: 5px;
      text-align: center;
      margin: auto;
      margin-top: -12px;
      position: relative;
      z-index: 1;
    }

    .name {
      font-family: "Jost";
      font-weight: normal;
      font-weight: 500;
      font-size: 24px;
      color: $LumiRose;
    }

    .position {
      font-family: "Raleway";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
    }

    .message {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      margin-top: 20px;
    }

    .rate {
      width: auto;
      margin: auto;
      margin-top: 20px;
      text-align: center;

      .figure {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: black;
      }

      .persession {
        font-family: "Raleway";
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        color: $Graphite;
        margin-left: 20px;
      }
    }
  }

  .card-border {
    background-image: url("../../images/bg_border.png");
    background-size: 100% 100%;
    border-radius: 32px 0px;
    position: relative;
    padding: 3px;
  }

  .find {
    width: 300px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 26px;
    gap: 24px;
    background: $LumiRose;
    border-radius: 32px 0px;
    margin: auto;
    margin-top: 0px;

    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .frameAHide {
    display: none;
    transition: 1.5s;
  }

  .frameBShow {
    display: block !important;
    transition: 1.5s;
  }

  .frameBHide {
    display: none;
    transition: 1.5s;
  }

  .li-selected {
    background-color: #ec7363 !important;
    color: white !important;
  }

  .li-selected-followup-questions {
    background-color: #69C1BE !important;
    color: white !important;
  }

}

.progressBar {
  p {
    font-size: 20px;
  }

  .container {
    background-color: #fff;
    margin-top: 100px;
    width: 80%;
    border-left: 3px solid #fdbc5b;
    border-bottom: 1px solid #fdf4f3;
    border-right: 3px solid #69c1be;
    padding: 0;
  
  }

  .progress-bar {
    background-color: #fdf4f3;
    border-radius: none;
    color: #35505C;
    font-size: 20px;
    font-weight: 600;
    min-height: 50px;
    padding: 10px 0;
    text-align: center;
    border-right: 2px solid #69c1be;
  }

}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .get-started {
    margin-left: 10px;
    margin-right: 10px;

    .get-started-box {
      width: 95%;
    }

    .col-4,
    .col-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .p-5 {
      padding: 0px !important;
    }

    .practitioner-card {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: white;
      border-radius: 28px 0px;
      text-align: center;

      img {
        width: 100px;
        border-radius: 4px;
      }

      .top-badge {
        width: 80px;
        font-family: "Raleway";
        font-weight: normal;
        font-weight: 700;
        letter-spacing: 0.5px;
        font-size: 10px;
        background-color: $LumiRose;
        color: white;
        border-radius: 4px;
        padding: 5px;
        text-align: center;
        margin: auto;
        margin-top: -12px;
        position: relative;
        z-index: 1;
      }

      .name {
        font-family: "Jost";
        font-weight: normal;
        font-weight: 500;
        font-size: 24px;
        color: $LumiRose;
      }

      .position {
        font-family: "Raleway";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
      }

      .message {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        margin-top: 20px;
      }

      .rate {
        width: auto;
        margin: auto;
        margin-top: 20px;
        text-align: center;

        .figure {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: black;
        }

        .persession {
          font-family: "Raleway";
          font-style: italic;
          font-weight: 300;
          font-size: 16px;
          color: $Graphite;
          margin-left: 20px;
        }
      }
    }

    .find {
      margin-top: 30px;
    }

    .get-started-box-content {
      ul {
        li {
          width: 95%;
        }
      }
    }
  }

}
