.practitioner-profile {
  padding: 50px 30px;

  .title-bar {
    display: flex;

    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
    }

    .datetime-pill {
      position: absolute;
      right: 30px;
      transform: translateY(-10px);
    }
  }

  .dash-prac-profile-left {
    float: left;

    .profession-box {
      background-color: #fef2f0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      span {
        display: block;
        margin-top: 5px;
        font-family: "Raleway";
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: #455b66;
      }

      textarea {
        width: 100%;
        border: none;
        background-color: transparent;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;

        resize: none;
        overflow: hidden;

        &:active,
        &:focus {
          outline: none;
          border: 1px solid grey;
          border-radius: 10px;
          background: white;
        }
      }
    }

    .description-box {
      background-color: #fbe3e0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 30px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      span {
        display: block;
        margin-top: 5px;
        font-family: "Raleway";
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: #455b66;
      }

      textarea {
        width: 100%;
        border: none;
        background-color: transparent;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;

        resize: none;
        overflow: hidden;

        &:active,
        &:focus {
          outline: none;
          border: 1px solid grey;
          border-radius: 10px;
          background: white;
        }
      }
    }

    .display-hidden {
      display: none !important;
    }

    .certifications-box {
      background-color: #e1f3f2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }

      span {
        display: block;
        margin-top: 5px;
        font-family: "Raleway";
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: #455b66;
      }

      textarea {
        width: 100%;
        border: none;
        background-color: transparent;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;

        resize: none;
        overflow: hidden;

        &:active,
        &:focus {
          outline: none;
          border: 1px solid grey;
          border-radius: 10px;
          background: white;
        }
      }
    }

    .category-box {
      background-color: white;
      border: 1px solid #d7dcde;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;

        span {
          display: block;
          margin-top: 5px;
          font-family: "Raleway";
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: #455b66;
        }
      }

      .css-b62m3t-container {
        min-width: 300px;

        .lumi-category__control--is-focused {
          border: none;
          outline: none;
          box-shadow: none;
          outline-color: transparent;
        }

        .lumi-category__control {
          border: 1px solid #dbdbdb;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 16px;
        }

        .lumi-category__multi-value {
          background-color: white;
          border: 1px solid $LumiYellow;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: $LumiBodyText;
          border-radius: 100px;
          padding: 5px 10px;

          &:nth-child(2) {
            border: 1px solid $LumiOrange;
          }

          &:nth-child(3) {
            border: 1px solid $LumiRose;
          }

          &:nth-child(4) {
            border: 1px solid $LumiBlue;
          }

          &:nth-child(5) {
            border: 1px solid $LumiYellow;
          }

          &:nth-child(6) {
            border: 1px solid $LumiPink;
          }

          &:nth-child(7) {
            border: 1px solid $LumiLightGreen;
          }
        }

        .lumi-category__clear-indicator {
          display: none;
        }

        .lumi-category__multi-value__remove {
          border-radius: 100px;
          background-color: $LumiGray;
          color: white;
          padding: 0px;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
          margin-left: 5px;

          &:hover {
            background-color: $LumiGray;
            color: white;
          }
        }

        .lumi-category__indicator-separator {
          opacity: 0;
        }

        .lumi-drop-indicator {
          display: block;
          width: 50px;
          height: 37px;
          border-radius: 100px;
          background-color: $LumiYellow;
          text-align: center;
          margin-right: 10px;

          img {
            width: 20px;
            margin-top: 8px;
            margin-left: 14px;
          }
        }
      }
    }

    .multi-select-box {
      background-color: white;
      border: 1px solid #d7dcde;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 32px 30px;
      gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      .labels {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: $Graphite;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
      }

      input[type="text"],
      select {
        width: 35%;
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: $Graphite;
        padding: 5px 15px 5px 15px;
        border-radius: 5px;
        border: 2px solid lightgray;
        outline: none !important;
        margin-top: 2px;
        display: block;
      }

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;

        span {
          display: block;
          margin-top: 5px;
          font-family: "Raleway";
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: #455b66;
        }
      }

      .css-b62m3t-container {
        min-width: 300px;

        .lumi-category__control--is-focused {
          border: none;
          outline: none;
          box-shadow: none;
          outline-color: transparent;
        }

        .lumi-category__control {
          border: 1px solid #dbdbdb;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 16px;
        }

        .lumi-category__multi-value {
          background-color: white;
          border: 1px solid $LumiYellow;
          font-family: "Raleway";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: $LumiBodyText;
          border-radius: 100px;
          padding: 5px 10px;

          &:nth-child(2) {
            border: 1px solid $LumiOrange;
          }

          &:nth-child(3) {
            border: 1px solid $LumiRose;
          }

          &:nth-child(4) {
            border: 1px solid $LumiBlue;
          }

          &:nth-child(5) {
            border: 1px solid $LumiYellow;
          }

          &:nth-child(6) {
            border: 1px solid $LumiPink;
          }

          &:nth-child(7) {
            border: 1px solid $LumiLightGreen;
          }
        }

        .lumi-category__clear-indicator {
          display: none;
        }

        .lumi-category__multi-value__remove {
          border-radius: 100px;
          background-color: $LumiGray;
          color: white;
          padding: 0px;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
          margin-left: 5px;

          &:hover {
            background-color: $LumiGray;
            color: white;
          }
        }

        .lumi-category__indicator-separator {
          opacity: 0;
        }

        .lumi-drop-indicator {
          display: block;
          width: 50px;
          height: 37px;
          border-radius: 100px;
          background-color: $LumiYellow;
          text-align: center;
          margin-right: 10px;

          img {
            width: 20px;
            margin-top: 8px;
            margin-left: 14px;
          }
        }
      }
    }

    .single-select-box {
      background-color: white;
      border: 1px solid #d7dcde;
      align-items: flex-start;
      padding: 30px 32px 30px;
      // gap: 20px;
      border-radius: 16px;
      margin-bottom: 20px;

      /** Radio button start **/

      .radio-buttons {
        display: flex;
        flex-wrap: wrap;
      }

      .radio-button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 10px;
        position: relative;
        padding: 5px 20px;

        border: 1px solid #ec7363;
        border-radius: 20px;
        cursor: pointer;
      }

      .radio-button input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .radio-tick {
        width: 15px;
        height: 15px;
        border: 2px solid #9dc8f5;
        border-radius: 50%;
        margin-right: 10px;
      }

      .radio-button input[type="radio"]:checked + .radio-tick::before {
        content: " ";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .radio-button input[type="radio"]:checked ~ .radio-tick {
        background-color: #007bff;
      }

      /** Radio button end **/

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;

        span {
          display: block;
          margin-top: 5px;
          font-family: "Raleway";
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: #455b66;
        }
      }

      .css-b62m3t-container {
        min-width: 300px;
      }
    }

    .quill {
      width: 100%;

      .ql-toolbar {
        background-color: #f0f0f0;
      }

      .ql-container {
        background-color: white;

        .ql-editor {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }

  .dash-prac-profile-right {
    width: 25%;
    min-width: 250px;
    float: right;
    margin-left: 20px;

    h2 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }

    .photo-box {
      width: 100%;
      background-color: #fef2f0;
      padding: 30px 32px 30px;
      border-radius: 16px;

      .profile-photo {
        display: block;
        width: 90%;
        height: 90%;
        margin: auto;
        margin-top: 20px;
        border-radius: 100%;
        cursor: pointer;
        background-color: white;
      }

      .image-file-input {
        display: none;
      }
    }
  }

  .age-scope {
    .image-file-input {
      display: none;
    }
  }

  .clearboth {
    clear: both;
  }

  .save-button {
    font-family: Raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $LumiBodyText;
    margin-top: 20px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 15px 10px 15px;
    border-radius: 20px 0px 20px 0px;
    cursor: pointer;
    background-color: $LumiRose;
    color: white;
    outline: none;
    border: none;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.autoComplete {
  width: 35%;
  height: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: $Graphite;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  border: 2px solid lightgray;
  outline: none !important;
  margin-top: 2px;
  display: block;
}
