.practitioner-messages {
  padding: 50px 30px;
  .title-bar {
    display: flex;
    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
    }
    .datetime-pill {
      position: absolute;
      right: 30px;
      transform: translateY(-10px);
    }
  }

  .messages-container {
    width: 100%;
    display: flex;
    .messages-inbox-list {
      width: 30%;
      height: calc(100vh - 270px);
      overflow-y: auto;
      .messages-inbox-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px;
        border-radius: 1000px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-color: rgba(248, 171, 155, 0.2);
        }

        img {
          width: 46px;
          height: 46px;
          border-radius: 46px;
          border: 4px solid $LumiBlue;
        }
        .messages-inbox-item-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 24px;
          background: $LumiRose;
          color: white;
          font-family: Raleway;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          padding: 0px;
          transform: translate(-20px, 10px);
          div {
            margin-top: -3px;
          }
        }
        .messages-inbox-item-badge-hidden {
          opacity: 0;
        }
        .messages-inbox-item-name {
          width: 70%;
          color: $LumiRose;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 0.32px;
          margin-left: 10px;
        }
        .messages-inbox-item-date {
          width: auto;
          text-align: right;
          color: $LumiBodyText;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.32px;
          padding-right: 10px;
          white-space: nowrap;
        }
      }

      .messages-inbox-item-active {
        color: white !important;
        background-color: $LumiBlue !important;

        .messages-inbox-item-name,
        .messages-inbox-item-date {
          color: white !important;
        }
      }
    }

    .messages-convo-thread {
      width: 90%;
      height: calc(100vh - 270px);
      margin-left: 30px;
      border-radius: 16px;
      border: 1px solid rgba(69, 91, 102, 0.24);
      padding: 10px;
      display: flex;
      flex-direction: column-reverse;

      .messages-convo-thread-chatbar {
        display: flex;
        border-radius: 14px;
        border: 1px solid $LumiBlue;

        .message-input-container {
          width: 100%;
          .message-input {
            margin: 15px;
            margin-top: 10px;
            margin-bottom: 0px;
            width: 100%;
            resize: none;
            height: 33px;
            min-height: 33px;
            border: none;
            outline: none;

            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .message-send {
          width: 40px;
          height: 40px;
          margin-top: 5px;
          margin-right: 15px;
          cursor: pointer;

          &:hover {
            filter: contrast(3);
          }
        }
      }

      .messages-chat-items {
        width: 100%;
        height: calc(100vh - 300px);
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
        overflow-x: hidden;
        .messages-chat-item {
          display: flex;
          border-radius: 14px;
          border: 1px solid rgba(253, 188, 91, 0.1);
          padding: 10px;
          margin-bottom: 10px;
          .messages-chat-avatar {
            width: 46px;
            height: 46px;
            border-radius: 46px;
            border: 4px solid $LumiBlue;
          }

          .messages-chat-container {
            margin-left: 15px;
            .messages-chat-name {
              color: $LumiRose;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
            }
            .messages-chat-message {
              color: #35505c;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }

            .sending {
              display: none;
            }
          }
        }

        .messages-chat-item-self {
          display: flex;
          border-radius: 14px;
          border: 1px solid rgba(253, 188, 91, 0.1);
          padding: 10px;
          margin-bottom: 10px;
          flex-direction: row-reverse;
          background: rgba(248, 171, 155, 0.2);
          .messages-chat-avatar {
            width: 46px;
            height: 46px;
            border-radius: 46px;
            border: 4px solid $LumiBlue;
          }

          .messages-chat-container {
            margin-right: 15px;
            .messages-chat-name {
              text-align: right;
              color: $LumiRose;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
            }
            .messages-chat-message {
              text-align: right;
              color: #35505c;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
            }

            .sending {
              text-align: right;
              font-size: 14px;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}
