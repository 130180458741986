.faqs {
  margin-top: 70px;

  .title-bar {
    display: flex;

    h1 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      color: $LumiBlue;
      margin-bottom: 30px;
      text-align: center;
    }

  }

  .dash-faq-heading {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    color: $LumiBlue;

    text-align: center;
  }

  .dash-faq-left {
    text-align: center;
    margin-bottom: 40px;
  }

  .faqs-section {

    .bar {
      color: black;
      border-radius: 5px;
      padding: 25px;
      display: flex;
      justify-content: flex-start;

      .icon {
        text-align: center;
      }

      .faqs-text {
        margin-top: 4px;
        width: 100%;
      }
    }
  }

  .collapsing {
    margin-top: 4px;
    color: black;
    transition-property: height;
    transition-duration: 200ms;
    overflow: hidden;
  }

  .sidebar-navigation-asterisk {
    margin-bottom: 5px;
  }

  .question-section {
  background: transparent;
  border: none;
  border-radius: 8px;

  cursor: pointer;
  width: 90%;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
  font-weight: bold;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}

.answer-divider {
  border-top: 1px solid #fdbc5b;
}

.padding-40 {
  margin: 20px 0;
}

.font-orange {
  color: #555555;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}



}
