#quick-access-wrap {
  margin-top: 100px;
  padding: $SidePadding;

  #quick-access {
    width: 100%;
    left: 0px;
    right: 0px;

    .lumilife-logo-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 95%;
      margin-top: -20px;

      a,
      div {
        margin-left: 20px;
      }
      a {
        img {
          width: 36px;
        }
      }

      div {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.02em;
        color: $LumiBlue;
      }
    }

    .quick-access-links {
      width: 90%;
      margin: auto;
      padding-left: 10em;
      h1 {
        font-family: "Raleway";
        font-style: normal;
        font-size: 16px;
        color: $LumiBlue;
        font-weight: bold;
      }
      .list {
        display: block;
        margin: 0;
        padding: 0;
        margin-top: 25px;
        li {
          margin: 0;
          padding: 0;
          list-style: none;
          margin-bottom: 10px;
          a {
            font-family: "Raleway";
            font-style: normal;
            font-size: 16px;
            color: #444444;
          }
        }
      }
    }
    .for-practitioners-links {
      width: 90%;
      margin: auto;
      h1 {
        font-family: "Raleway";
        font-style: normal;
        font-size: 16px;
        color: $LumiRose;
        font-weight: bold;
      }
      .list {
        display: block;
        margin: 0;
        padding: 0;
        margin-top: 25px;
        li {
          margin: 0;
          padding: 0;
          list-style: none;
          margin-bottom: 10px;
          a {
            font-family: "Raleway";
            font-style: normal;
            font-size: 16px;
            color: #444444;
          }
        }
      }
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  #quick-access-wrap {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: $SidePadding;

    #quick-access > .row > .col-3:nth-child(1) {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }

    #quick-access > .row > .col-3:nth-child(4) {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: flex-start;
      margin: 0px;
      padding: 0px;
      margin-top: 20px;
    }

    #quick-access {
      width: 100%;
      left: 0px;
      right: 0px;

      > .row > .col-3 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .lumilife-logo-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 95%;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;

        a,
        div {
          margin-left: 20px;

          &:nth-child(1) {
            margin-left: 0px;
          }
        }

        a {
          img {
            width: 36px;
          }
        }

        div {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.02em;
          color: $LumiBlue;
        }
      }

      .quick-access-links {
        width: 100%;
        margin: auto;
        padding-left: 0em;
        h1 {
          font-family: "Raleway";
          font-style: normal;
          font-size: 16px;
          color: $LumiBlue;
          font-weight: bold;
        }
        .list {
          display: block;
          margin: 0;
          padding: 0;
          margin-top: 25px;
          li {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 10px;
            a {
              font-family: "Raleway";
              font-style: normal;
              font-size: 16px;
              color: #444444;
            }
          }
        }
      }
      .for-practitioners-links {
        width: 90%;
        margin: auto;
        margin-top: 0px;
        h1 {
          font-family: "Raleway";
          font-style: normal;
          font-size: 16px;
          color: $LumiRose;
          font-weight: bold;
        }
        .list {
          display: block;
          margin: 0;
          padding: 0;
          margin-top: 25px;
          li {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 10px;
            a {
              font-family: "Raleway";
              font-style: normal;
              font-size: 16px;
              color: #444444;
            }
          }
        }
      }
    }
  }
}
