.practitioner-signup-body {
  h1 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 54px;
    line-height: 120%;
    text-align: center;
    color: $LumiBlue;
  }

  .circles-container {
    margin-top: 80px !important;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 180%;
    text-align: center;
    color: $LumiBodyText;
    .circles {
      width: 50px;
      height: 50px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      border-radius: 999px;
    }

    .circle1 {
      border: 4px solid $LumiYellow;
    }

    .circle2 {
      border: 4px solid $LumiPink;
    }

    .circle3 {
      border: 4px solid $LumiRose;
    }

    h2 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $LumiYellow;
      margin-top: 20px;
    }

    .yellow {
      color: $LumiYellow;
    }

    .pink {
      color: $LumiPink;
    }

    .rose {
      color: $LumiRose;
    }

    p {
      display: block;
      width: 80%;
      padding: 0px;
    }
  }

  .video-service {
    .left {
      width: 65%;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        color: $LumiPink;
      }
      p {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;
      }
    }
    .right {
      img {
        width: 90%;
        margin: auto;
        border-radius: 0px 32px;
      }
    }
  }

  .simplified-admin {
    margin-top: 100px !important;
    .left {
      width: 65%;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        color: $LumiYellow;
      }
      p {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: $LumiBodyText;
      }
    }
    .right {
      img {
        width: 90%;
        margin: auto;
        border-radius: 0px 32px;
      }
    }
  }

  .faqs {
    width: 70%;
    margin: auto;
    h2 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      color: $LumiBlue;
    }
    .accordion-item {
      border: none;
      border-bottom: 1px solid #cccccc;
      outline: none;
    }
    .accordion-button {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: $LumiOrange;
      padding: 30px 0px 20px 0px;
      border-radius: 0px;
      border: none;
      background: white;
      width: 100%;
      text-align: left;
    }
    .accordion-body {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: $LumiBodyText;
      line-height: 180%;
      letter-spacing: 0.02em;
      padding: 0px 0px 20px 0px;
    }
    .accordion-button:focus {
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.125);
    }
    .accordion-button:not(.collapsed) {
      background-color: white;
    }
    .accordion-button:not(.collapsed)::after {
      opacity: 0;
    }
    .accordion-button::after {
      display: block;
      float: right;
      background: none;
      content: "+";
      font-size: 73px;
      font-weight: 100;
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .practitioner-signup-body {
    padding-top: 1px;
    h1 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      text-align: center;
      color: $LumiBlue;
      margin-top: 0px !important;
      padding-top: 70px;
      // background-color: #fdf2ef;
      margin-bottom: 0px;
    }

    .circles-container {
      margin-top: 0px !important;
      padding-top: 30px !important;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 180%;
      text-align: center;
      color: $LumiBodyText;
      // background-color: #fdf2ef;
      padding-bottom: 50px;

      .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .circles {
        width: 50px;
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        border-radius: 999px;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .circle1 {
        border: 4px solid $LumiYellow;
      }

      .circle2 {
        border: 4px solid $LumiPink;
      }

      .circle3 {
        border: 4px solid $LumiRose;
      }

      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: $LumiYellow;
        margin-top: 20px;
      }

      .yellow {
        color: $LumiYellow;
      }

      .pink {
        color: $LumiPink;
      }

      .rose {
        color: $LumiRose;
      }

      p {
        display: block;
        width: 90%;
        padding: 0px;
      }
    }

    .video-service {
      margin-top: 70px !important;

      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .left {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h3 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 47px;
          color: $LumiPink;
        }
        p {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: $LumiBodyText;
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
      .right {
        img {
          width: 100%;
          margin: auto;
          border-radius: 0px 32px;
        }
      }
    }

    .simplified-admin {
      margin-top: 70px !important;

      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6:nth-child(1) {
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
      }

      .col-6:nth-child(2) {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
      }

      .left {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h3 {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 47px;
          color: $LumiYellow;
        }
        p {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: $LumiBodyText;
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
      .right {
        img {
          width: 100%;
          margin: auto;
          border-radius: 0px 32px;
        }
      }
    }

    .faqs {
      width: 100%;
      margin: auto;
      padding: 20px;
      h2 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        color: $LumiBlue;
      }
      .accordion-item {
        border: none;
        border-bottom: 1px solid #cccccc;
        outline: none;
      }
      .accordion-button {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: $LumiOrange;
        padding: 30px 0px 20px 0px;
        border-radius: 0px;
        border: none;
        background: white;
        width: 100%;
        text-align: left;
      }
      .accordion-body {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $LumiBodyText;
        line-height: 180%;
        letter-spacing: 0.02em;
        padding: 0px 0px 20px 0px;
      }
      .accordion-button:focus {
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.125);
      }
      .accordion-button:not(.collapsed) {
        background-color: white;
      }
      .accordion-button:not(.collapsed)::after {
        opacity: 0;
      }
      .accordion-button::after {
        display: block;
        float: right;
        background: none;
        content: "+";
        font-size: 73px;
        font-weight: 100;
      }
    }
  }
}
