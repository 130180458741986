.client-signup {
  .header {
    display: none;
  }

  .left {
    min-height: calc(100vh - 90px);
    border-radius: 0px 364px 0px 0px;
    margin-top: 20px;
    background-image: url(../../images/bg_signup.png);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: top;

    h1 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 72px;
      line-height: 110%;
      color: #ffffff;
      padding-left: $SidePadding;
      margin-top: 220px;
    }

    h3 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 160%;
      color: #ffffff;
      padding-left: $SidePadding;
    }

    .question {
      position: relative;
      margin-top: 100px;
      margin-left: $SidePadding;
      h3 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        color: #ffffff;
        padding-left: 0px;
        margin-bottom: 20px;
      }

      .join {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #ffffff;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        text-decoration: none;
        background-color: $LumiBlue;
        padding: 20px 20px 20px 20px;
        border-radius: 30px 0px 30px 0px;
        margin-left: 0px;
        cursor: pointer;
      }
    }
  }

  .right {
    min-height: calc(100vh - 165px);
    margin-top: min(200px, 5%);
    display: flex;
    flex-direction: column;
    justify-content: top;

    h3 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 110%;
      color: $LumiBlue;
      text-align: center;
    }

    .fullname-container {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;

      .labels {
        margin: 0;
      }

      input[type="text"] {
        width: 100%;
      }
    }

    .labels {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: $Graphite;
      width: 65%;
      margin-left: auto;
      margin-right: auto;
    }

    input[type="text"],
    select {
      width: 65%;
      height: 50px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $Graphite;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      border: 2px solid lightgray;
      outline: none !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }

    .password {
      width: 100% !important;
      height: 50px;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $Graphite;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      border: 2px solid lightgray;
      outline: none !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }

    .password-wrapper {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .eye {
        width: 34px;
        position: absolute;
        right: 15px;
        top: 25px;
        cursor: pointer;
      }
    }

    button {
      width: 65%;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      letter-spacing: 1px;
      text-align: center;
      padding: 20px 25px 20px 25px;
      border: none;
      border-radius: 30px 0px 30px 0px;
      background-color: $LumiBlue;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      outline-color: transparent;
    }

    .terms {
      width: 65%;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      color: #90a4af;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;

      a {
        color: $Graphite;
      }
    }

    h5 {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: $Graphite;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 50px;

      a {
        cursor: pointer;
        color: $LumiRose;

        :hover {
          color: $LumiRose;
        }
      }
    }

    .should-match {
      font-weight: bold;
      color: #d62d30;
      text-align: center;
    }

    .required {
      font-weight: bold;
      color: #d62d30;
    }

    .input-wrapper {
      text-align: center;
    }

    .error {
      color: $LumiRose;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

/*Mobile*/

@media only screen and (max-width: $MobileViewThreshold) {
  .client-signup {
    .header {
      display: none;
    }

    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .left {
      min-height: calc(100vh - 200px);
      border-radius: 0px 120px 0px 0px;
      margin-top: 20px;
      background-image: url(../../images/bg_signup.png);
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: top;

      h1 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        color: #ffffff;
        padding-left: 10px;
        margin-top: 100px;
        width: 90%;

        br {
          display: none;
        }
      }

      h3 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #ffffff;
        padding-left: 10px;
      }

      .question {
        position: relative;
        left: 0px;
        bottom: 0px;
        margin-top: 20px;
        margin-left: 0px;
        h3 {
          font-family: "Jost";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
          padding-left: 10px;
          margin-bottom: 20px;
        }

        .join {
          font-family: "Raleway";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #ffffff;
          text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          text-decoration: none;
          background-color: $LumiBlue;
          padding: 20px 20px 20px 20px;
          border-radius: 30px 0px 30px 0px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .right {
      height: 975px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: top;

      h3 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        color: $LumiBlue;
        text-align: center;
      }

      .fullname-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;

        .col-6 {
          padding: 0px !important;
        }

        .mt-5 {
          margin-top: 1.5rem !important;
        }

        .labels {
          margin: 0;
        }

        input[type="text"] {
          width: 100%;
        }
      }

      .labels {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: $Graphite;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      input[type="text"],
      select {
        width: 90%;
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $Graphite;
        padding: 5px 15px 5px 15px;
        border-radius: 5px;
        border: 2px solid lightgray;
        outline: none !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
      }

      .password {
        width: 100% !important;
        height: 50px;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $Graphite;
        padding: 5px 15px 5px 15px;
        border-radius: 5px;
        border: 2px solid lightgray;
        outline: none !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
      }

      .password-wrapper {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        .eye {
          width: 34px;
          position: absolute;
          right: 15px;
          top: 25px;
          cursor: pointer;
        }
      }

      button {
        width: 90%;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        letter-spacing: 1px;
        text-align: center;
        padding: 20px 25px 20px 25px;
        border: none;
        border-radius: 30px 0px 30px 0px;
        background-color: $LumiBlue;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        outline-color: transparent;
      }

      .terms {
        width: 90%;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #90a4af;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;

        a {
          color: $Graphite;
        }
      }

      h5 {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: $Graphite;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;

        a {
          cursor: pointer;
          color: $LumiRose;

          :hover {
            color: $LumiRose;
          }
        }
      }

      .should-match {
        font-weight: bold;
        color: #d62d30;
        text-align: center;
      }

      .required {
        font-weight: bold;
        color: #d62d30;
      }

      .input-wrapper {
        text-align: center;
      }

      .error {
        color: $LumiRose;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    #quick-access-wrap {
      margin-top: 0px;
    }
  }
}
